import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { isEmpty } from "lodash";
import { filterData } from "../../../../src/utils/kendo";
import { setNewMyTasks } from "../../../actions/task";
import { kendo, number, route } from "../../../config";
import { icon, label, placeholder } from "../../../config/constants";
import { getQueryParams } from "../../../helper/common";
import { useCombinationKeys } from "../../../helper/commonHooks";
import { compareLists } from "../../../utils/common";
import { useKanbanUpdates } from "../../KanbanView/hooks/kanban.hooks";
import { checkForWhitespace } from "../../OverallSearch/search.helper";
import { setName } from "../../Tasks/tasks.helper";
import { handleTaskKeyUpdate } from "../../Tasks/tasks.service";
import { checkIsPersonalProject, setSidebarFieldsDisabled } from "../sidebar.helper";

/**
 * HeaderForm component handles inputs of TaskSidebarHeader
 * @props {}
 */
const HeaderForm = (props) => {
  const location = useLocation();
  const [taskName, setTaskName] = useState("");
  const [project, setProject] = useState();
  const [userProjectsList, setUserProjectsList] = useState([]);
  const { isTaskNameEmpty, setIsTaskNameEmpty } = props;
  const { allProjectsList, privacyStatus } = useSelector((state) => state.tasks.defaultDetails);
  const { user } = useSelector((store) => store.auth);
  const { isNewTask, task } = useSelector((state) => state.taskSidebar);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.name);
  const { pathname } = useLocation();
  const [projectList, setProjectList] = useState([]);
  const ctrlS = useCombinationKeys("s");
  const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);
  const { selectedView } = useSelector((state) => state.tasks);
  const sharedProjectId = getQueryParams("pId", location.search);
  const { allTasks } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();

  const taskNameInput = useRef(null);
  const { updateKanbanName } = useKanbanUpdates();

  /**
   * used to get all the projects, a user is part of
   * @author {Prachi Jain}
   */
  useEffect(() => {
    const userProjectList = allProjectsList?.filter((project) => {
      return project.RoleId || (project.IsPersonal && project.user === user.id);
    });
    setUserProjectsList(userProjectList);
  }, [allProjectsList]);

  /**
   * sets focus to task name input
   */
  useEffect(() => {
    if (taskNameInput.current) {
      taskNameInput.current.focus();
    }
  }, [task.taskId, isNewTask]);

  /**
   * Ctrl+s shortcut to save
   */
  useEffect(() => {
    handleCtrlShortCut();
  }, [ctrlS]);

  /**
   * sets intital value of project where task is created.
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (isNewTask && !compareLists(projectList, userProjectsList) && !isCloneTask) {
      if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH }) || matchPath(pathname, { path: route.PRIVATE_ROUTE.ORGANISATION_DASHBOARD })) {
        let paramsData = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
        paramsData = paramsData ? paramsData : matchPath(pathname, { path: route.PRIVATE_ROUTE.ORGANISATION_DASHBOARD });
        const { params } = paramsData;
        let intitialProject = userProjectsList?.find((p) => p.value == (params?.projectId ? params?.projectId : parseInt(sharedProjectId)));
        intitialProject = intitialProject ? intitialProject : userProjectsList?.find((p) => p.IsPersonal === number.ONE);
        setProject(intitialProject);
      }
    }
  }, [userProjectsList, isNewTask, pathname]);

  /**
   * Initial project in case of clone task
   */
  useEffect(() => {
    if (isCloneTask && isNewTask) {
      const requiredProjectId = cloneTask?.flowElements?.findLast((f) => f.isCompleted)?.assignmentProjectId;
      let intitialProject = userProjectsList?.find((p) => p.value == (requiredProjectId ? requiredProjectId : parseInt(sharedProjectId)));
      intitialProject = intitialProject ? intitialProject : userProjectsList?.find((p) => p.IsPersonal === number.ONE);
      setProject(intitialProject);
    }
  }, [isCloneTask, isNewTask]);

  /**
   * sets value of project in newTaskData global state
   */
  useEffect(() => {
    if (project) {
      project && handleTaskKeyUpdate(isNewTask, "project", project?.value);
    }
  }, [project]);

  useEffect(() => {
    if (isEmpty(taskName)) setIsTaskNameEmpty(true);
    else setIsTaskNameEmpty(false);
  }, [taskName]);

  // used to prepolulate task name of selected workflow
  useEffect(() => {
    if (isNewTask && !isCloneTask && workflowBasicDetails?.TaskName) {
      setTaskName(workflowBasicDetails?.TaskName);
      handleTaskKeyUpdate(isNewTask, "name", workflowBasicDetails?.TaskName?.trim());
    }
  }, [isNewTask, isCloneTask, workflowBasicDetails?.TaskName]);

  useEffect(() => {
    if (isNewTask && isCloneTask && cloneTask?.name) {
      setTaskName(cloneTask?.name);
      handleTaskKeyUpdate(isNewTask, "name", cloneTask?.name?.trim());
    }
  }, [isCloneTask, isNewTask, cloneTask?.name]);

  useEffect(() => {
    if (task?.taskId && !isCloneTask) {
      setTaskName(task?.Name);
    }
  }, [task?.taskId, isCloneTask]);

  /**
   * Sets user project lists for task creation
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (projectList && userProjectsList && !compareLists(projectList, userProjectsList)) setProjectList([...userProjectsList]);
  }, [userProjectsList]);

  /**
   * Sets name in newTaskData state
   * @param {void}
   * @returns {void}
   */
  const handleCtrlShortCut = async () => {
    if (isNewTask) {
      await handleTaskNameOnBlur();
    }
  };

  /**
   * handles project dropdown state change
   * @param {void}
   * @returns {void}
   */
  const handleProjectChange = (e) => {
    setProject(e.target.value);
  };

  /**
   * changes task name on blur event
   * @param {void}
   * @returns {void}
   */
  const handleTaskNameOnBlur = async (e) => {
    if (!isEmpty(taskName) && !isNewTask) {
      const response = await handleTaskKeyUpdate(isNewTask, "name", taskName?.trim());
      if (selectedView === label.KANBAN) updateKanbanName(task?.taskId, taskName?.trim());
      if (response) {
        const payload = setName(allTasks, taskName?.trim(), task?.taskId);
        dispatch(setNewMyTasks(payload));
      }
    }
  };

  /**
   * filter out the user project search data.
   * @param {*} event
   */
  const filterChange = (event) => {
    let project = userProjectsList;
    setProjectList(filterData(event.filter, project));
  };

  /**
   * Handles change for Task name input
   * @param {Event} e
   * @author Himanshi Chawla
   */
  const handleChange = (e) => {
    const value = e.target.value;
    if (!checkForWhitespace(value)) return;
    setTaskName(value);
    if (isNewTask) handleTaskKeyUpdate(isNewTask, "name", value);
  };

  /**
   * custom dropdown item render for user project list
   * @returns JSX
   * @author Himanshu Negi
   *
   */
  const itemRender = (li, itemProps) => {
    const currentProject = allProjectsList?.find((project) => itemProps?.dataItem?.value === project?.ProjectId);
    const isPersonalProject = checkIsPersonalProject(currentProject?.ProjectId);
    const currentProjectPrivacy = allProjectsList?.find((project) => itemProps?.dataItem?.value === project?.ProjectId)?.Privacy;
    const currentProjectPrivacyIcon = privacyStatus?.find((p) => p.PrivacyId === currentProjectPrivacy)?.Icon;
    const itemChildren = (
      <span key={itemProps?.index} className='privacy-dropdown-values d-flex align-content-center' title={itemProps?.dataItem?.label}>
        <div className='privacy-dropdown-values-icon mr-2'>{isPersonalProject ? icon.USER_ICON : icon[currentProjectPrivacyIcon]}</div>
        <span className='text-truncate'>{itemProps?.dataItem?.label}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * custom dropdown value render for user project list
   * @returns JSX
   * @author Himanshu Negi
   */
  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const currentProject = allProjectsList?.find((project) => value?.value === project?.ProjectId);
    const isPersonalProject = checkIsPersonalProject(currentProject?.ProjectId);
    const currentProjectPrivacy = allProjectsList?.find((project) => value?.value === project?.ProjectId)?.Privacy;
    const currentProjectPrivacyIcon = privacyStatus?.find((p) => p.PrivacyId === currentProjectPrivacy)?.Icon;
    const children = [
      <span key={value?.value} className='d-flex align-items-center justify-content-center'>
        <span>{isPersonalProject ? icon.USER_ICON : icon[currentProjectPrivacyIcon]}</span>
        <span>{value?.label}</span>
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <>
      {isNewTask && (
        <div className='new-task-project-select'>
          <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
            <DropDownList
              className='max-width-300 tour-task-sidebar-project'
              name='taskProject'
              data={projectList ? projectList : userProjectsList}
              textField='label'
              dataItemKey='value'
              id='dropdown-header-form-project-list'
              value={project}
              onChange={handleProjectChange}
              filterable={true}
              onFilterChange={filterChange}
              itemRender={itemRender}
              valueRender={valueRender}
            />
          </Tooltip>
        </div>
      )}
      <Input
        type='text'
        name='taskName'
        placeholder={placeholder.ENTER_TASK_NAME}
        autoComplete='off'
        id='tname'
        autoFocus
        ref={taskNameInput}
        value={taskName}
        onChange={handleChange}
        onBlur={handleTaskNameOnBlur}
        onFocus={() => setIsTaskNameEmpty(false)}
        className={`tour-task-sidebar-taskname ${isTaskNameEmpty ? "form-control-invalid" : ""}`}
        required={isTaskNameEmpty && true}
        maxLength={kendo.TASK_INPUT_LENGTH}
        disabled={loader || setSidebarFieldsDisabled()}
      />
      {loader && <div className='preloader loader-wrap'></div>}
    </>
  );
};

export default HeaderForm;
