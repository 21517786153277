import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setIsDisabled, setSelectedTab } from "../../../actions/projectSidebar";
import { getTimezones } from "../../../actions/userProfile";
import { label, number } from "../../../config";
import NotificationControl from "../../../shared/layouts/Header/NotificationControl/NotificationControl";
import UserProfile from "../../../shared/layouts/Header/UserProfile/UserProfile";
import { getAllQueues } from "../../../shared/services/queues.service";
import { getManagerList } from "../../../shared/services/usersetup.service";
import Team from "../../Projects/Team/Team";
import { PROJECT_TYPE, TAB } from "../projectSidebar.constants";
import ProjectSidebarSections from "./ProjectSidebarSections/ProjectSidebarSections";

const ProjectSidebarContent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { selectedTab, projectDetails, type } = useSelector((state) => state.projectSidebar);
  const [timezoneNames, setTimezoneNames] = useState([]);
  const [managerNames, setManagerNames] = useState([]);
  const allQueues = useSelector((state) => state.allQueues);

  useEffect(() => {
    if (type === PROJECT_TYPE.COMPANY_PROJECT && (!projectDetails?.RoleId || projectDetails?.RoleId !== number.FOUR)) {
      dispatch(setIsDisabled(false));
    }
  }, [projectDetails]);

  /**
   * Fetches timezones and sets the timeZoneList state.
   * @author Bhavana
   **/
  useEffect(() => {
    (async () => {
      if (type === PROJECT_TYPE.PERSONAL_PROJECT) {
        let timezoneListResponse = await dispatch(getTimezones());
        let managerListResponse = await getManagerList(user?.companyId);
        setTimezoneNames(timezoneListResponse);
        setManagerNames(managerListResponse);
      }
    })();
  }, []);

  useEffect(() => {
    if (type === PROJECT_TYPE.COMPANY_PROJECT) {
      if (allQueues.length === number.ZERO) dispatch(getAllQueues());
    }
  }, []);

  const handleSelect = (e) => {
    dispatch(setSelectedTab(e.selected));
  };

  return (
    <>
      <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
        <div>
          <TabStrip selected={selectedTab} onSelect={handleSelect}>
            {type === PROJECT_TYPE.PERSONAL_PROJECT && (
              <TabStripTab title={TAB.PROFILE} contentClassName='sidebar-content-tabs'>
                <UserProfile timezoneNames={timezoneNames} managerNames={managerNames} />
              </TabStripTab>
            )}

            <TabStripTab title={type === PROJECT_TYPE.PERSONAL_PROJECT ? label.CONFIGURE : TAB.SETTINGS} contentClassName='sidebar-content-tabs'>
              <ProjectSidebarSections />
            </TabStripTab>
            <TabStripTab title={TAB.WORKFLOWS} contentClassName='sidebar-content-tabs'>
              <ProjectSidebarSections workflows={true} />
            </TabStripTab>

            {type === PROJECT_TYPE.COMPANY_PROJECT && (
              <TabStripTab title={TAB.MEMBERS} contentClassName='sidebar-content-tabs'>
                <Team />
              </TabStripTab>
            )}
            {type === PROJECT_TYPE.PERSONAL_PROJECT && (
              <TabStripTab title={label.NOTIFICATION_CONTROL} contentClassName='sidebar-content-tabs'>
                <NotificationControl />
              </TabStripTab>
            )}
          </TabStrip>
        </div>
      </Tooltip>
    </>
  );
};

export default ProjectSidebarContent;
