import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { label } from "../../../config";
import store from "../../../store";
import { isTrueBit } from "../../../utils";
import { GridContext } from "../../../utils/kendo";
import { editWorkflowStages } from "../../services/workflow.services";

/**
 * Component for managing the default assignee in a workflow or task stage
 * @author Bhavana
 */
export const WorkflowDefaultAssignee = (props) => {
  const { dataItem } = props;

  const { task, newTaskData } = useSelector((state) => state.taskSidebar);
  const { assignees } = useSelector((state) => state.projectSidebar);

  const dispatch = useDispatch();
  const stagesContext = React.useContext(GridContext);
  const { isDisabled, workflowElement, taskElement, workflowId, data, projectId, incomingStage, setIncomingStage } = stagesContext;
  let assigneeList = assignees && [{ label: label.UNASSIGNED, value: null }, ...assignees];
  const [workflowDefaultAssignee, setWorkflowDefaultAssignee] = useState();
  const [taskDefaultAssignee, setTaskDefaultAssignee] = useState();
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);

  /**sets default assignee for task stages */
  useEffect(() => {
    if (newTaskData?.id || task.taskId && props.dataItem?.defaultAssignee) setTaskDefaultAssignee(assigneeList?.find((a) => a.value === props.dataItem?.defaultAssignee));
  }, [props?.dataItem]);

  /**sets default assignee for workflow stages */
  useEffect(() => {
    if(workflowId && props.dataItem?.defaultAssignee){
     setWorkflowDefaultAssignee(assigneeList?.find((a) => a.value === props.dataItem?.defaultAssignee));
    }
  }, [workflowId, props?.dataItem]);

  /**hides assignee dropdown */
  useEffect(() => {
    !props.dataItem.isFinalStage && !props.dataItem?.isHidden ? setShowAssigneeDropdown(true) : setShowAssigneeDropdown(false);
  }, [props.dataItem]);

  const handleWorkflowDefaultAssigneeRow = (event, stage) => {
    setWorkflowDefaultAssignee(event.value);
    if (workflowId) {
      let payload = {
        workflowId: workflowId,
        stageId: dataItem.value,
        defaultAssignee: event.value.value,
        projectId,
        incomingStage,
        isHidden: dataItem.isHidden,
      };
      store.dispatch(editWorkflowStages(payload));
    }

    if (incomingStage?.value === dataItem.value) {
      const { active, id, isFinalStage, sortId, taskCount, value, label } = dataItem;
      const payload = { active, defaultAssignee: event.value.value, id, isFinalStage, sortId, taskCount, value, label };
      setIncomingStage(payload);
    }
    data.map((item) => {
      if (item.value == stage) item.defaultAssignee = event.value.value;
    });
    stagesContext.setStages(data);
  };
  const handleTaskDefaultAssigneeRow = (event, stage) => {
    setTaskDefaultAssignee(event.value);
    if (task.taskId) {
      let payload = {
        taskId: task.taskId,
        stageId: dataItem.value,
        defaultAssignee: event.value.value,
        isHidden: dataItem.isHidden,
      };
      store.dispatch(editWorkflowStages(payload));
    }
    stagesContext?.stages.map((item) => {
      if (item.value == stage) item.defaultAssignee = event.value.value;
    });
    dispatch(stagesContext.setStages(stagesContext?.stages));
  };

  const handleDefaultAssigneeRow = (event, stage) => {
    if (workflowElement) {
      handleWorkflowDefaultAssigneeRow(event, stage);
    } else if (taskElement) {
      handleTaskDefaultAssigneeRow(event, stage);
    }
  };
  return (
    <td className='cursor-pointer'>
      {showAssigneeDropdown && (
        <DropDownList
          className='w-100'
          data={assigneeList}
          value={
            task.taskId || newTaskData?.id
              ? taskDefaultAssignee
                ? taskDefaultAssignee
                : { label: label.UNASSIGNED, value: null }
              : workflowDefaultAssignee
              ? workflowDefaultAssignee
              : { label: label.UNASSIGNED, value: null }
          }
          dataItemKey='value'
          textField='label'
          onChange={async (event) => {
            handleDefaultAssigneeRow(event, props.dataItem?.value);
          }}
          disabled={isDisabled || (task.IsTaskComplete && !isTrueBit(task, "InApproval"))}
        />
      )}
    </td>
  );
};
