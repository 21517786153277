import React, { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import _ from "lodash";
import { icon, label, number, route, tooltip } from "../../../../config";
import { assignedTypes } from "../../../Tasks/tasks.constants";
import { addTaskApprovalsHistory } from "../../../Tasks/tasks.service";
import ApprovalAssignment from "./ApprovalAssignment";
import { approvalActionValidation, approverActionitemRender, approverActionvalueRender, getApprovalCurrentProject, isApprover, resetApprovalsDropdown } from "./approval.helper";

/**
 * Approver View component, it is rendered in approval component
 * @returns JSX
 * @author Himanshu Negi
 */
const ApproverView = () => {
  const [approverAction, setApproverAction] = React.useState(null);
  const [approvalAssignType, setApprovalAssignType] = React.useState(null);
  const [approvalAssignId, setApprovalAssignId] = React.useState(null);
  const [approvalRelatedAssignedType, setApprovalRelatedAssignedType] = React.useState(null);
  const [approvalRelatedAssignedId, setApprovalRelatedAssignedId] = React.useState(null);
  const [relatedAssignedIdList, setRelatedAssignedIdList] = React.useState([]);
  const [approvalAssignee, setApprovalAssignee] = React.useState(null);
  const [approvalAssigneeName, setApprovalAssigneeName] = React.useState("");
  const [note, setNote] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showNoteInput, setShowNoteInput] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [isTaskLocked, setIsTaskLocked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [targetElement, setTargetElement] = React.useState(null);
  const wrapper = React.useRef(null);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.auth);
  const taskMatchPath = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
  const { isNewTask, task, approvalLogs, flowElements } = useSelector((store) => store.taskSidebar);
  const { defaultDetails } = useSelector((store) => store.tasks);
  const projectWorkflows = useSelector((store) => store.projectWorkflows);
  const getApprovalCurrentProjectArgs = { approvalAssignId, approvalAssignType, task, defaultDetails, isNewTask };
  const approvalActionValidationArgs = { approverAction, approvalLogs, approvalAssignType, task, approvalAssignId, approvalAssignee, approvalRelatedAssignedType, approvalRelatedAssignedId };
  const approvalProjectName = useMemo(() => (approvalAssignType?.key === number.TWO ? approvalAssignId?.label : approvalAssignId?.ProjectName), [approvalAssignId, approvalAssignType]);

  /**
   * field to check if current flow is locked or not
   * @author Himanshu Negi
   */
  const isCurrentFlowLocked = useMemo(() => {
    const reversedFlowElements = _.reverse([...flowElements]);
    const currentProject = reversedFlowElements?.find((flow) => flow?.isCompleted === number.ONE);
    return currentProject ? currentProject.isFlowLocked : number.ZERO;
  }, [flowElements]);

  /**
   * field to check if approval is self approval
   * @author Himanshu Negi
   */
  const isSelfApproval = useMemo(() => {
    const approvalInitiateLog = approvalLogs?.findLast((log) => log.ApprovalStatus === number.ONE);
    return approvalInitiateLog?.IsSelfApproval;
  }, [approvalLogs]);

  /**
   * approver action list
   * @author Himanshu Negi
   */
  const approverActionList = useMemo(() => {
    if (isSelfApproval) {
      return defaultDetails?.approvalStatus?.filter((action) => ![number.ONE, number.THREE, number.FOUR, number.SEVEN, number.EIGHT].includes(action?.StatusId));
    }
    return defaultDetails?.approvalStatus?.filter((action) => ![number.ONE, number.SEVEN].includes(action?.StatusId));
  }, [isSelfApproval, defaultDetails?.approvalStatus]);

  /**
   * It enables the approval assignment inputs only if approver chooses reassign action
   * @author Himanshu Negi
   */
  useEffect(() => {
    const disableValue = approverAction?.StatusId === number.FOUR || approverAction?.StatusId === number.FIVE ? false : true;
    setDisable(disableValue);
  }, [approverAction]);

  /**
   * sets initial values of approvalAssignType, approvalAssignId, approvalRelatedAssignedType, approvalRelatedAssignedId,
   * @author Himanshu Negi
   */
  useEffect(() => {
    (async () => {
      const resetPayload = { task, setApprovalAssignType, setApprovalAssignId, setApprovalRelatedAssignedType, setRelatedAssignedIdList, setApprovalRelatedAssignedId, defaultDetails };
      await resetApprovalsDropdown(resetPayload);
    })();
  }, [task?.approvalAssignedType, task?.approvalAssignedId, task?.approvalAssignee, task?.approvalRelatedAssignedId]);

  useEffect(() => {
    setIsTaskLocked(isCurrentFlowLocked ? true : false);
  }, [isCurrentFlowLocked]);

  /**
   * handles approver action
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleApproverAction = async () => {
    setLoading(true);
    if (!approvalActionValidation(approvalActionValidationArgs)) {
      setLoading(false);
      return;
    }
    const payload = {
      taskId: task?.taskId,
      assignedType: approvalAssignType?.key ? approvalAssignType?.key : task?.approvalAssignedType,
      assignedId: approvalAssignId?.value ? approvalAssignId?.value : task?.approvalAssignedId,
      assignedBy: user.id,
      currentProject: getApprovalCurrentProject(getApprovalCurrentProjectArgs),
      assignee: approvalRelatedAssignedType?.value === number.TWO ? approvalRelatedAssignedId?.value : projectWorkflows[approvalRelatedAssignedId?.value]?.DefaultAssignee,
      approvalStatus: approverAction?.Status,
      comment: note,
      approvalRelatedAssignedType: approvalRelatedAssignedType?.value,
      approvalRelatedAssignedId: approvalRelatedAssignedId?.value,
      taskHistoryId: task.taskHistoryId,
      isTaskLocked,
    };
    if (taskMatchPath) {
      await dispatch(addTaskApprovalsHistory(payload, approverAction?.Status, taskMatchPath?.params?.projectId));
    }
    setLoading(false);
  };

  /**
   * Onchange handler for approval textarea
   * @param {Object} e
   * @returns void
   * @author Himanshu Negi
   */
  const onChangeHandler = (e) => {
    if (!e) return;
    setNote(e.target.value);
    e.target.style.height = "30px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  /**
   * sets the value for isTaskLocked on click of icon
   * @author {Himanshu Negi}
   */
  const toggleTaskLock = useCallback(async () => {
    await hideTooltip();
    setIsTaskLocked(!isTaskLocked);
    await showTooltip();
  }, [isTaskLocked]);

  /**
   * show controlled tooltip on mouse over
   * @author Himanshu Negi
   */
  const showTooltip = useCallback(async () => {
    setOpen(true);
    await setTargetElement(wrapper.current);
  }, [wrapper?.current]);

  /**
   * hide controlled tooltip on mouse leave
   * @author Himanshu Negi
   */
  const hideTooltip = useCallback(async () => {
    setOpen(false);
    await setTargetElement(null);
  }, [wrapper?.current]);

  return (
    <React.Fragment>
      <div className='approver-container d-flex flex-column justify-content-center col-md-12'>
        <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
          <div className={`${isApprover(task) || (isApprover(task) && approvalLogs?.length) ? "approval-approver-view" : "approval-non-approver-view"}`}>
            <div className='d-flex align-items-center justify-content-center mr-3'>
              <div className='mb-0'>
                <div className='approval-current-project'>
                  <span className='d-flex align-items-center justify-content-center'>{assignedTypes.find((t) => t.key == approvalAssignType?.key)?.icon}</span>
                  <span>{approvalProjectName}</span>
                </div>
              </div>
            </div>
            <div className='approver-secondary-actions'>
              {(isApprover(task) || (isApprover(task) && approvalLogs?.length)) && (
                <>
                  <button id='approval-comment' onClick={() => setShowNoteInput(!showNoteInput)} className='approval-originator-note-icon' title={tooltip.ADD_NOTE}>
                    {icon.COMMENT}
                  </button>
                  {task?.IsTaskLocked == number.ZERO && (
                    <Tooltip position='bottom' anchorElement='target' parentTitle={true} open={open} targetElement={targetElement}>
                      <button
                        id='approval-view-lock-task-btn'
                        ref={wrapper}
                        className={`approver-lock-icon ${isTaskLocked ? "text-blue" : "text-black"}`}
                        title={isTaskLocked ? tooltip.UNLOCK_TASK : tooltip.LOCK_TASK}
                        onClick={toggleTaskLock}
                        onMouseOver={showTooltip}
                        onMouseLeave={hideTooltip}>
                        {isTaskLocked ? icon.APPROVAL_LOCK : icon.UNLOCK}
                      </button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div>
              <div className='approver-actions-logs d-flex align-items-start justify-content-between flex-grow-1'>
                <div className='approver-actions d-flex justify-content-end align-items-center'>
                  <div>
                    {!isApprover(task) || (!isApprover(task) && approvalLogs?.length) ? (
                      <div className='nonapprover-section'>
                        <span className='d-flex align-items-center justify-content-center text-blue'>{icon.APPROVAL_ICON}</span>
                        <span>{approvalRelatedAssignedId?.label}</span>
                      </div>
                    ) : (
                      <DropDownList
                        id='approver-action-drop'
                        value={approverAction}
                        data={approverActionList}
                        itemRender={approverActionitemRender}
                        valueRender={approverActionvalueRender}
                        textField='Status'
                        dataItemKey='StatusId'
                        onChange={(obj) => {
                          setApproverAction(obj.value);
                        }}
                        defaultItem={{ Status: "Select", StatusId: number.ZERO }}
                        popupSettings={{ className: "approval-action-dropdown" }}
                        className={`${!approverAction ? "form-control-invalid" : ""}`}
                      />
                    )}
                  </div>
                  {(isApprover(task) || (isApprover(task) && approvalLogs?.length)) && (
                    <button
                      id='approver-action-btn'
                      className='d-flex align-items-center justify-content-center cursor-pointer bg-transparent approval-action-tick'
                      onClick={handleApproverAction}
                      disabled={loading || !approverAction}>
                      {icon.CHECKBOX}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='approval-assign-form mb-2'>
            {showNoteInput && (
              <div>
                <textarea id='approver-note' className='approval-note-area form-control' onChange={onChangeHandler} placeholder={label.ADD_NOTE} rows={number.ONE} autoFocus />
              </div>
            )}
            <div className='approver-reassign-option'>
              {(approverAction?.StatusId === number.FIVE || approverAction?.StatusId === number.FOUR) && (
                <div>
                  <ApprovalAssignment
                    approverAction={approverAction}
                    approvalAssignType={approvalAssignType}
                    setApprovalAssignType={setApprovalAssignType}
                    approvalAssignId={approvalAssignId}
                    setApprovalAssignId={setApprovalAssignId}
                    approvalRelatedAssignedType={approvalRelatedAssignedType}
                    setApprovalRelatedAssignedType={setApprovalRelatedAssignedType}
                    approvalRelatedAssignedId={approvalRelatedAssignedId}
                    setApprovalRelatedAssignedId={setApprovalRelatedAssignedId}
                    relatedAssignedIdList={relatedAssignedIdList}
                    setRelatedAssignedIdList={setRelatedAssignedIdList}
                    approvalAssignee={approvalAssignee}
                    setApprovalAssignee={setApprovalAssignee}
                    approvalAssigneeName={approvalAssigneeName}
                    setApprovalAssigneeName={setApprovalAssigneeName}
                    disable={disable}
                    setDisable={setDisable}
                  />
                </div>
              )}
            </div>
          </div>
          {/* <OriginatorView /> */}
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default ApproverView;