import { requestMethod } from "../config/";
import config from "../env.config";
import { fetch } from "../shared/services/api.service";
import { checkApiSuccess } from "../utils/index";
import { resetNewProfile } from "./adminUserOnboard";
import { updateCurrUser } from "./auth";

/**
 * handling api for update password
 * @param {credential of the user}
 * @returns {void}
 */

export const updateProfile = (profileData) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/user/updateUserProfile`,
      data: profileData
    }
    let response = await fetch(requestConfig, true, true)
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};


/**
 * handling api for get user profile info
 * @param {credential of the user}
 * @returns {void}
 */

export const getUserDetails = (userId) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/user/getUserDetails`,
      params: {
        userId: userId
      }
    }
    let response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      dispatch(updateCurrUser(response.data.data[0]))
    }
  };
};


/**
 * handling api for getting Timezones
 * @returns {array}
 */
export const getTimezones = () => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/app/getTimezones`,
    }
    let response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getTimezoneByCoordinates = (timezone) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/app/getTimezoneByCoordinates`,
      params: {
        latitude: timezone.latitude,
        longitude: timezone.longitude
      }
    }
    let response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
}
