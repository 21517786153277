import React, { useContext, useEffect } from "react";
import { button } from "../../../../config";
import "./userProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../actions/userProfile";
import _, { isEmpty } from "lodash";
import { getCompressedBase64 } from "../../../../utils/common";
import { directory, label } from "../../../../config/constants";
import { UserProfileContext } from "./UserProfile.context";
import LeftProfilePanel from "./LeftProfilePanel";
import RightProfilePanel from "./RightProfilePanel";
import { toggleProjectSidebar } from "../../../../actions/projectSidebar";
import { PROJECT_TYPE } from "../../../../components/ProjectSidebar/projectSidebar.constants";
import { updateCurrUser } from "../../../../actions/auth";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";

/**
 * UserProfileForm component is responsible for setting up an user profile.
 * parent component: UserProfile
 * @author Bhavana
 */
const UserProfileForm = ({timezoneNames, managerNames}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const { displayName, selectedImageFolder, profileData, manager, taskCode, jobTitle, timeZone, selectedImage, reminderTime, setManagerList, setManager, setInitialManagerList, setTimeZoneList, setInitialTimeZone } = useContext(UserProfileContext);
  /**
 * call fetchManagerList function when component renders and setTimeZoneList with the list of timezones
 * @author Bhavana
 */
   useEffect(() => {
    fetchManagerList();
    setTimeZoneList(timezoneNames);
    setInitialTimeZone(timezoneNames);
  }, [managerNames, timezoneNames])

  /**
   * Fetches the manager list, sets the manager state, and updates the manager list state.
   * @author Bhavana
   */
  const fetchManagerList = async () => {
   let managerList = managerNames;
    managerList = [{ label: label?.UNASSIGNED, value: null }, ...managerList]
    const managerName = managerList?.find((manager) => manager?.value === user?.Manager);
    setManager(managerName);
    if (managerList) {
      setManagerList(managerList);
      setInitialManagerList(managerList);
    }
  }
  /**
 * Updates the user profile setup by creating a payload and call updateProfile api.
 * @author Bhavana
 */
  const updateProfileSetup = async (profileData) => {
    const payloadProfile = {
      ...profileData,
      fileContent: selectedImageFolder ?selectedImageFolder : null,
      userId: user?.id
    };
    const formData =  createFormData();
    appendFieldsFromObject(formData, payloadProfile);
    const response = dispatch(updateProfile(formData));
    return response;
  };

  /**
 * call updateProfileSetup function to updates the changes and dispatching the updateCurrUser action to update the redux.
 * @author Bhavana
 */
  const saveProfileData = async (e) => {
    e.preventDefault();
    const response = await updateProfileSetup(profileData)
    dispatch(updateCurrUser({ Name: displayName, JobTitle: jobTitle, Manager: manager?.value, timezone: timeZone, UserReminderTime: reminderTime, PhotoLink: selectedImage, ...(response && { projectCode: taskCode }) }));
  }

  /**
 * Hides the project sidebar by dispatching the toggleProjectSidebar action with the PERSONAL_PROJECT type.
 * @author Bhavana
 */
  const hideProjectSidebar = () => {
    dispatch(toggleProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, false));
  }

  return (
    <div className="container">
      <form>
        <div className="row">
          <LeftProfilePanel />
          <RightProfilePanel />
        </div>
        <div className="text-right mt-2">
          <button type="button" className="btn btn-secondary mr-2 btn-width" id="userprofileform-hide-project-sidebar" onClick={hideProjectSidebar}>{button.CANCEL}</button>
          <button type="submit" className="btn btn-primary btn-width" id="userprofileform-save-profile-data" onClick={saveProfileData} disabled={(isEmpty(displayName) || isEmpty(taskCode)) && true}>{button.SAVE}</button>
        </div>
      </form>
    </div>
  );
};

export default UserProfileForm;
