
import { getUserDetails, updateProfile } from "../../actions/userProfile";
import { route } from "../../config";
import { directory } from "../../config/constants";
import { checkProfileUpdated } from "../../shared/services/usersetup.service";
import store from "../../store";
import { getCompressedBase64 } from "../../utils";
import { updateOrg } from "../Admin/admin.service";

/**
 * Handles actions for a new user.
 *
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {Object} response - The response object from the login API call.
 * @param {Object} history - The history object from React Router.
 * @returns {void}
 * @author Bhavana
 */
export const handleNewUser = async (dispatch, response, history) => {
  const { adminOnboard } = store.getState();
  const { adminUserOnboardData, profileSetUp } = adminOnboard;
  history.push(route.PRIVATE_ROUTE.TASKS.PATH);
  await updateProfileSetup(dispatch, response, profileSetUp);
  await dispatch(getUserDetails(response?.data?.user?.id));
  if (adminUserOnboardData?.emailId) {
    await updateOrgSetup(response, adminUserOnboardData);
  }
};

/**
 * Updates the profile setup for a new user.
 *
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {Object} response - The response object from the login API call.
 * @param {Object} profileSetUp - The profile setup data for the user.
 * @returns {void}
 * @author Bhavana
 */
export const updateProfileSetup = async (dispatch, response, profileSetUp) => {
  const payloadProfile = {
    fileContent: await getCompressedBase64(profileSetUp?.PhotoLink),
    key: directory.USER_PROFILE + "/" + response?.data.user.id,
    profileData: profileSetUp,
    userId: response?.data.user.id
  };
  dispatch(updateProfile(payloadProfile));
};

/**
 * Updates the organization setup for a new user.
 *
 * @param {Object} response - The response object from the login API call.
 * @param {Object} adminUserOnboardData - The onboard data for the admin user.
 * @returns {void}
 * @author Bhavana
 */
export const updateOrgSetup = async (response, adminUserOnboardData) => {
  const updatedAdminDashboardData = { ...adminUserOnboardData, orgId: response?.data.user.companyId };
  const payload = {
    userId: response?.data.user.id,
    data: updatedAdminDashboardData,
    fileContent: await getCompressedBase64(adminUserOnboardData?.orgPicture),
    key: directory.ORGANIZATION + "/" + response?.data.user.name + "_" + response?.data.user.companyId
  };
  await updateOrg(payload, "", adminUserOnboardData);
};

/**
 * Fetches profile status for the given email
 * @param {string} email - The email address to check for profile update status.
 * @author Bhavana
 */
export const checkProfileExist = async (mailId, companyId) =>{
  if(mailId){
    const encodeMail = encodeURIComponent(mailId);
    const encodedCompanyId = encodeURIComponent(companyId)
    const response = await checkProfileUpdated(encodeMail, encodedCompanyId);
      return response;
  }
}
