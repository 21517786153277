import React, { useEffect, useState } from 'react';
import { icon, label } from '../../config';
import { formatDate } from '../editor.helper';
import { usePopup } from '../../helper/commonHooks';
import { PopupPropsContext } from '@progress/kendo-react-popup'
import RelationList from '../../components/OverallSearch/RelationList';
import { useDispatch, useSelector } from 'react-redux';
import { getRelationData } from '../../components/Tasks/tasks.service';


/**
* Non Editable Editor 
* PC KendoEditor
* @author Shivam Mishra
*/
const TaskDetails = ({ taskInfo }) => {
    const { Name, ProjectTaskId, Priority, Assignee, DueDate, IsTaskComplete } = taskInfo;
    const { show, setShow, anchor, contentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
    const dispatch = useDispatch();
    const [showPopup , setShowPopup] = useState(false) ; 
    const { task } = useSelector((state) => state.taskSidebar);
      /**
     * load link task relation list.
     * @call {*} getLinkTasks
     */
      useEffect(()=>{
        (async () => {
        await dispatch(getRelationData())
      })()
    }, [])
    return (
        <>
            <div className='row'>
            {task.taskId && <div className='editor-link-icon position-absolute cursor-pointer ' ref = {anchor} onClick={() => {setShowPopup(true);setShow(true)}}>{icon.LINK_ATTACHMENT}</div>}
                <div className='col-md-12'>
                    <div className='container pt-2 pb-2 details-popup-minwidth'>
                        <div className='d-flex d-flex justify-content-between justify-content-center align-items-center mt-3 mb-3'>
                            <div>{ProjectTaskId}</div>
                            <div className='ml-2 mr-2'>{Name}</div>
                            <div className='d-flex justify-content-center'>{IsTaskComplete ? icon.GREEN_CHECK : icon.CHECK}</div>
                        </div>
                        <div className='d-flex d-flex justify-content-between justify-content-center align-items-center'>
                            <div>{Assignee ?? label.UNASSIGNED}</div>
                            <div className='ml-2 mr-2'>{icon[Priority]}</div>
                            <div>{formatDate(DueDate)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && 
                <PopupPropsContext.Provider value={props => ({
                    ...props,
                    appendTo: anchor.current
                })}>
                    <RelationList
                        anchor={anchor}
                        show={show}
                        setShowPopup={setShowPopup}
                        selectedItem={taskInfo}
                        relationListRef={contentRef}
                        blurTimeoutRef={blurTimeoutRef}
                        onOpen={onOpen}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        setShow={setShow}
                    />
                 </PopupPropsContext.Provider>
            }
        </>

    );
};

export default React.memo(TaskDetails);