import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAssignees } from "../../../../actions/projectSidebar";
import { setFocusedElement, setStages } from "../../../../actions/taskSidebar";
import { number } from "../../../../config";
import TaskStagesGrid from "../../../../shared/components/TaskAndWorkflowStages/TaskStagesGrid";
import { getProjectDetails, getProjectUsers } from "../../../../shared/services/projects.service";
import { addWorkflowElement, deleteWorkflowElement, editWorkflowElement, getWorkflowDefaults } from "../../../../shared/services/workflow.services";
import FlowElements from "../../../Workflow/FlowElements/FlowElements";
import { isUserPartOfProject } from "../../sidebar.helper";
import PanelVisibilty from "./PanelVisibilty";
import ViewSelection from "./ViewSelection";
import "./WorkflowElements.scss";

/**
 * Child components: ProjectStagesGrid, FlowElements
 * Parent component:  TaskSidebarContent
 * It renders flow details panel in task sidebar which consists of stages grid and flow elements
 */
const WorkflowElementsPanel = () => {
  const dispatch = useDispatch()
  const { task, newTaskData, flowElements, isNewTask, stages } = useSelector((state) => state.taskSidebar)
  const [flowElementData, setFlowElementData] = useState([])
  const [existingFlowElements, setExistingFlowElements] = useState([])
  const { user } = useSelector((state) => state.auth)
  const team = useSelector((state) => state.team);
  const { workflowBasicDetails, workflowStages } = useSelector((state) => state.taskWorkflowDetails)
  const { defaultDetails } = useSelector((state) => state.tasks);

  /**filters assignee options for stages */
  const assigneeOptions = () => {
    let options = [];
    team?.projectMembers?.map(user => {
      if (user.RoleId === number.FOUR || user.RoleId === number.FIVE) { options.push({ value: user.UserId, label: user.Name }) }
      return null;
    })
    return options;
  }

  /**dispatches required actions for project details */
  useEffect(() => {
    (async () => {
      let projectId = isNewTask ? workflowBasicDetails?.ProjectId : task?.EntityProjectId
      if (projectId) {
        isNewTask && dispatch(getProjectDetails({ userId: user.id, projectId: projectId, companyId: user.companyId }));
        projectId !== user?.myProjectId && dispatch(getProjectUsers(projectId));
      }
    })();
  }, [workflowBasicDetails?.ProjectId, task?.EntityProjectId]);


  /**gets workflow defaults*/
  useEffect(() => {
    (async () => {
      await getWorkflowDefaults({ userId: user.id, projectId: task.CurrentProject ? task.CurrentProject : newTaskData.project })
    })();
  }, [task.CurrentProject, newTaskData.project]);

  /**sets flow elements for new/existing task*/
  useEffect(() => {
    const currentProjectIndex = flowElements?.findLastIndex(f => f.isCompleted)
    const flowElementList = flowElements?.filter((f, i) => i >= currentProjectIndex)
    setFlowElementData(isNewTask ? flowElements : flowElementList)
    setExistingFlowElements(isNewTask ? flowElements : flowElementList)
  }, [flowElements, task?.EntityProjectId]);

  /**sets assignee options for stages*/
  useEffect(() => {
    (async () => {
      dispatch(setAssignees(assigneeOptions()))
    })();
  }, [team]);

  const addWorkflowFlowElement = async (payload) => {
    const response = await dispatch(addWorkflowElement(payload));
    return response;
  }

  const editWorkflowFlowElement = async (payload) => {
    await dispatch(editWorkflowElement(payload))
  }

  const deleteWorklowFlowElement = async (payload) => {
    await deleteWorkflowElement(payload)
  }

  /**
   * checks if the user has access of current assigned project.
   * @param {Void}
   * @returns {Boolean}
   * @author Himanshu Negi
   */
  const userHasAccess = () => {
    const projectId = task?.CurrentAssignedType === number.TWO ? defaultDetails?.assigneeList?.find((item) => item?.value === task?.CurrentAssignedId)?.myProjectId : task?.CurrentAssignedId;
    const project = defaultDetails?.allProjectsList?.find((item) => item.ProjectId === projectId);
    const userHasProjectAccess = isUserPartOfProject(defaultDetails, project);
    return userHasProjectAccess;
  }

  /**
   * set focused element id
   * @author Dimple Sahota
   * */
  const handleFocus = () => {
    dispatch(setFocusedElement("task-workflow"));
  };

  return (
    <>
      <div onClick={handleFocus}>
        <div className='form-row mb-3'>
          {(isNewTask || !!(task?.EntityProjectId == task?.CurrentProject && task?.hasAccess && userHasAccess())) && (
            <TaskStagesGrid taskElement={true} projectId={isNewTask ? workflowBasicDetails?.ProjectId : task.EntityProjectId} stages={isNewTask ? workflowStages : stages} setStages={setStages} />
          )}
        </div>
        <div>
          <FlowElements
            flowElementData={flowElementData}
            setFlowElementData={setFlowElementData}
            existingFlowElements={existingFlowElements}
            entity={task?.taskId}
            taskElement={true}
            addFlowElement={addWorkflowFlowElement}
            deleteFlowElement={deleteWorklowFlowElement}
            editFlowElement={editWorkflowFlowElement}
          />
        </div>
        <ViewSelection />
        <PanelVisibilty />
      </div>
    </>
  );
};
export default WorkflowElementsPanel;
