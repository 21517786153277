import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { label, route } from "../../../../../config";
import { assignedTypes } from "../../../../Tasks/tasks.constants";
import { handleTaskKeyUpdate } from '../../../../Tasks/tasks.service';
import { setDisabledForAssignment } from '../../../sidebar.helper';
import { TaskAssignmentContext } from "./taskAssignment.context";
/**
 * TaskAssignment type drowpdown component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssignmentType = () => {
  const { assignmentType, setAssignmentType, setAssignedId, setRelatedAssignmentId, isDisabled, setIsDisabled, delegationType, setRelatedAssignmentList } = useContext(TaskAssignmentContext);
  const { isNewTask, task, newTaskData } = useSelector((state) => state.taskSidebar);
  const { pathname } = useLocation();
  const contentRef = useRef();
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);

  /**
   * sets initial state of assignmentType
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (!isNewTask) {
      const queueParams = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH })
      setDisabledForAssignment({ task, setIsDisabled, delegationType, queueParams });
      setAssignmentType(assignedTypes.find(t => t.key == task.CurrentAssignedType))
    }
  }, [task.taskId, isNewTask, setDisabledForAssignment, task.IsLocked])

  //sets assignType in redux in case of new task
  useEffect(() => {
    (async () => {
      if (isNewTask) {
        isNewTask && await handleTaskKeyUpdate(isNewTask, "assignedType", assignmentType?.key);
      }
    })();
  }, [isNewTask, assignmentType])


  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{itemProps.dataItem.icon}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.key}>{value.icon}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  /**
   * handles assignment type change
   * @param {Object} event
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (event) => {
    setAssignmentType(event.target.value);
    setAssignedId(null);
    setRelatedAssignmentId(null);
    setRelatedAssignmentList([])
  }

  return (
    <React.Fragment>
      <div ref={contentRef} className={`form-group tour-task-sidebar-assigntype ${showTaskDetailPanel && !isNewTask ? '' : 'col-lg-2 col-md-2 col-sm-2 assign-to-column'}`}>
        {showTaskDetailPanel && !isNewTask ? <span>{assignedTypes.find(t => t.key == assignmentType?.key)?.icon}</span> :
          <>
            <label htmlFor="">{label.ASSIGN_TO}</label>
            <DropDownList
              data={assignedTypes}
              value={assignmentType}
              onChange={handleChange}
              id="key"
              textField="label"
              itemRender={itemRender}
              valueRender={valueRender}
              disabled={!!(isDisabled)}
              popupSettings={{ appendTo: contentRef.current }}
            />
          </>}
      </div>
    </React.Fragment>

  )
}

export default React.memo(TaskAssignmentType);
