import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'

import { assignedTypes } from '../../../Tasks/tasks.constants';
import { assignmentTypeItem, assignmentTypeValue } from './approval.helper';
import { label } from '../../../../config';

/**
 * Approval Assignement type dropdown component, it is rendered in ApprovalAssignment Component 
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalAssignmentType = (props) => {
  const { approvalAssignType, setApprovalAssignType, setApprovalAssignId, setApprovalAssignee,
    setApprovalAssigneeName, disable, setApprovalRelatedAssignedId } = props;

/**
 * clears all state if assignee type changed
 * @param {Object} e 
 * @returns {Void}
 * @author Himanshu Negi 
 */
  const handleAssigneTypeChange = (e) => {
    if (!e) return;
    setApprovalAssignType(e.target.value)
    setApprovalAssignId(null)
    setApprovalAssignee(null)
    setApprovalAssigneeName("")
    setApprovalRelatedAssignedId(null);
  }

  return (
    <div className='form-group col-lg-2 col-md-2 col-sm-2 approval-assignetype-container'>
      <label htmlFor="">{label.ASSIGN_TO}</label>
      <DropDownList
        data={assignedTypes}
        textField="label"
        value={approvalAssignType}
        onChange={handleAssigneTypeChange}
        itemRender={assignmentTypeItem}
        valueRender={assignmentTypeValue}
        disabled={disable}
      />
    </div>
  )
}

export default ApprovalAssignmentType