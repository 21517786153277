import * as ACTION_TYPES from './action-types';

/**
* toggle notifications
* @param {data Boolean}
*/
export const setShowNotifications = (data) => {
  return {
    type: ACTION_TYPES.SHOW_NOTIFICATIONS,
    payload: data,
  }
}

/**
* set unclearedNotifications state
* @param {data Array}
*/
export const setUnclearedNotifications = (data) => {
  return {
    type: ACTION_TYPES.GET_UNCLEARED_NOTIFICATIONS,
    payload: data,
  }
}

/**
* empty unclearedNotifications state array
* @param {data Array}
*/
export const emptyUnclearedNotifications = () => {
  return {
    type: ACTION_TYPES.EMPTY_UNCLEARED_NOTIFICATIONS,
  }
}

export const setNotificationCount = (data) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_COUNT,
    payload: data
  }
}


export const setNotificationHeaderCounts = (data) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATION_HEADER_COUNTS,
    payload: data
  }
}

export const setNavigatedNotification = (data) => {
  return {
    type: ACTION_TYPES.SET_NAVIGATED_NOTIFICATION,
    payload : data
  }
}

export const setUserInNotificationPage = (data) => {
  return {
    type: ACTION_TYPES.IS_USER_IN_NOTIFICATION_PAGE,
    payload: data
  }
}