import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { arrayOfObjects, convertTo24HourFormat, formatDate, generateTimeArray, getDateAndTime, searchUsers } from '../editor.helper';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { icon, label, number } from '../../config';
import { Input } from '@progress/kendo-react-inputs';
import { splitString } from '../../utils';
import { useEditorContext } from '../editor.Context';

/**
* Updates User Chip  for Editor
* PC UpdateChip
* @author Shivam Mishra
*/
const UserChip = ({ smartChipId, setShowEditChip, assigneeList, setAssigneeList }) => {
    const { defaultDetails } = useSelector((state) => state.tasks);

    const editorState = useEditorContext();
    const { selectedIndex, setSelectedIndex, containerRef } = editorState;

    /**
    * Function to handle update events on user chip.
    * @param {Object} assignee - The assignee object.
    *@author Shivam Mishra
    */
    const handleClick = (assignee) => {
        const updateChip = document.getElementById(smartChipId);
        updateChip.innerHTML = assignee.label;
        setShowEditChip(false);
    };

    /**
    * Function to update assignee list .
    * @param {e} event.
    */
    const updateUserList = (e) => {
        setAssigneeList(searchUsers(e.target.value, defaultDetails, assigneeList))
    }

    useEffect(() => {
        setSelectedIndex(number.ZERO);
    }, [assigneeList]);

    /**
    * Function to handle update events on user chip.
    * @param {Object} assignee - The assignee object.
    *@author Shivam Mishra
    */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && assigneeList.length) {
            e.preventDefault();
            handleClick(assigneeList[selectedIndex])
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex(prevIndex => Math.max(number.ZERO , prevIndex - number.ONE));
        } else if (e.key === 'ArrowDown') {
            setSelectedIndex(prevIndex => Math.min(assigneeList.length - number.ONE, prevIndex + number.ONE));
        }
    }

    return (
        <div className='dt-editor-menu'>
            <div className='w-100' id={`update-editor-user-chip`}>
                <div className='d-flex justify-content-between p-3'>
                    <div className='font-weight-bold'>
                        {label.USER}
                    </div>
                    <div className='small'>{label.ESC_PRESS}</div>
                </div>
                <div className='ml-3 mr-3'><Input onChange={updateUserList} autoFocus={true} onKeyDown={handleKeyDown} /></div>
                <div ref={containerRef} className='dt-editor-users overflow-auto'>
                    {assigneeList?.map((assignee, index) => (
                        <div key={index} onClick={() => handleClick(assignee)} className={`dt-editor-users-list cursor-pointer d-flex ${index === selectedIndex ? 'user-selected' : ''}`} id={`update-user-chip-${index}`}>
                            <div className='mr-2'>{assignee.PhotoLink ? <img src={assignee.PhotoLink} className='userlist-dp rounded-circle' /> : <div className='userlist-icon'>{icon.ACCOUNT_CIRCLE}</div>}</div>
                            <div>
                                <div className="assignee-info d-block">{assignee.label}</div>
                                <div className='small info-text'>{assignee.Email}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

/**
* Updates Date Chip  for Editor
* PC UpdateChip
* @author Shivam Mishra
*/

const DateChip = ({ smartChipId, setShowEditChip }) => {
    const dateObject = getDateAndTime(document.getElementById(smartChipId).innerText);
    const [selected, setSelected] = useState(new Date(dateObject));

    /**
     * Function to handle update on date chip.
     *@author Shivam Mishra
     */
    const handleClick = () => {
        const updateChip = document.getElementById(smartChipId);
        updateChip.innerHTML = formatDate(selected);
        setShowEditChip(false);
    };


    return (
        <div className='zoom' tabindex="0">
            <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                id="update-chip-date-picker"
            />

            <div className='pl-3 pr-3 mb-3 d-flex justify-content-between position-relative' id="update-chip-time-picker">
                <button id="update-time-date-chip-btn" className='btn btn-primary float-right d-blocks' onClick={handleClick}>{label.SET}</button>
            </div>
        </div>
    );
};

/**
* Updates Date Chip  for Editor
* PC UpdateChip
* @author Shivam Mishra
*/
const DropDownChip = ({ smartChipId, setShowEditChip, smartChipIds }) => {
    const matchingObject = useMemo(() => {
        return arrayOfObjects?.find(obj => obj.id === parseInt(smartChipIds))?.value;
    }, [smartChipIds]);


    /**
    * Function to handle update on dropdown chip.
    * @param {String} name - The dd name
    * @author Shivam Mishra 
    */
    const handleClick = (name) => {
        const updateChip = document.getElementById(smartChipId);
        updateChip.innerHTML = name;
        setShowEditChip(false);
    };


    return (
        <div className='dt-editor-users overflow-auto w-100'>
            {matchingObject?.map((name, index) => (
                <div
                    className='dt-editor-users-item cursor-pointer word-break-all'
                    key={index}
                    id={`dd-options-${index}`}
                    onClick={() => handleClick(name)}
                >
                    {name}
                </div>
            ))}
        </div>
    );
};

const TimeChip = ({ smartChipId, setShowEditChip }) => {
    const chipdate = convertTo24HourFormat(document?.getElementById(smartChipId)?.innerHTML);
    const [selectedTime, setSelectedTime] = useState(chipdate);
    const timeArray = useMemo(() => generateTimeArray(), []);

    /**
     * Function to handle update on date chip.
     *@author Shivam Mishra
     */
    const handleClick = () => {
        const updateChip = document.getElementById(smartChipId);
        updateChip.innerHTML = selectedTime;
        setShowEditChip(false);
    };

    /**
     * Function to update on date state
     *@author Shivam Mishra
     */
    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    return (
        <div className='p-3 overflow-hidden'>
            <h6>{label.TIME}</h6>
            <div className='zoom'>
                <div className='mb-3 position-relative' id="editor-smart-time-picker">
                    <div className='time-options w-100 overflow-y-auto'>
                        {timeArray?.map((time) => (
                            <div key={time} className={`time-option cursor-pointer ${time === selectedTime ? 'blue active-time-list' : ''}`} onClick={() => { handleTimeChange(time) }}>
                                {time}
                            </div>
                        ))}
                    </div>
                    <button className='btn btn-primary float-left d-block mt-3' onClick={handleClick}>{label.SET}</button>
                </div>
            </div>
        </div>
    );
};

// Define a mapping from strings to components
const componentMap = {
    "user": UserChip,
    "date": DateChip,
    "time": TimeChip,
    "dd": DropDownChip,
};

/**
* Updates Chip  for Editor
* PC KendoEditor
* @author Shivam Mishra
*/
const UpdateChip = ({ smartChipId, setShowEditChip, assigneeList, setAssigneeList }) => {
    const [smartChip, userId, timestamp, smartChipType, smartChipIds] = splitString(smartChipId, "_") ?? [];

    const RenderedComponent = componentMap[smartChipType] || null;

    return (
        <div>
            {RenderedComponent && <RenderedComponent smartChipId={smartChipId} setShowEditChip={setShowEditChip} smartChipIds={smartChipIds} assigneeList={assigneeList} setAssigneeList={setAssigneeList} />}
        </div>
    );
};

export default React.memo(UpdateChip);