import React, { useContext, useEffect } from "react";
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import userDummy from "../../../../assets/images/dummy-user.jpg";
import { icon, label, number } from "../../../../config";
import "./userProfile.scss";
import { useSelector } from "react-redux";
import { useProfileContext, UserProfileContext } from "./UserProfile.context";
import { filterData } from "../../../../utils/kendo";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getLiveTimezone } from "../../../../helper/common";

/**
 * RightProfilePanel component is responsible for setting up an name, email, job title, task code, manager name, timezone, reminderTime.
 * parent component: UserProfileForm
 * @author Bhavana
 */
const RightProfilePanel = () => {
  const { Name, email, JobTitle, projectCode, PhotoLink, timezone, UserReminderTime } = useSelector((state) => state.auth.user);
  const { timeZoneList, setTimeZoneList, manager, setManager, setSelectedImage, timeZone, initialTimeZone, setTimeZone, displayName, setDisplayName, userEmail, setUserEmail, jobTitle, setJobTitle, taskCode, setTaskCode, reminderTime, setReminderTime, boardName, setBoardName, managerList, setManagerList, initialManagerList, setProfileData } = useProfileContext();

  /**
 * Initializes input values by setting state variables with provided data or default values.
 * @author Bhavana
 */
  const initializeInputValues = () => {
    setSelectedImage(PhotoLink ?? userDummy);
    setTimeZone(timezone);
    setDisplayName(Name ?? "");
    setUserEmail(email ?? "");
    setJobTitle(JobTitle ?? "");
    setTaskCode(projectCode ?? "");
    setReminderTime(UserReminderTime ?? "");
    setBoardName(Name ?? "");
  }

  /**
 * Initializes input values when the component renders.
 * @author Bhavana
 */
  useEffect(() => {
    initializeInputValues();
  }, [])

  /**
   * Handles input changes by updating corresponding state variables and the profileData object.
   * @author Bhavana
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'displayName':
        setDisplayName(value);
        setBoardName(value);
        setProfileData((prevState) => ({
          ...prevState,
          Name: value
        }));
        break;
      case 'jobTitle':
        setJobTitle(value);
        setProfileData((prevState) => ({
          ...prevState,
          JobTitle: value
        }));
        break;
      case 'taskCode':
        const upperCaseValue = value.toUpperCase();
        setTaskCode(upperCaseValue);
        setProfileData((prevState) => ({
          ...prevState,
          projectCode: upperCaseValue
        }));
        break;
      case 'reminderTime':
        setReminderTime(value);
        setProfileData((prevState) => ({
          ...prevState,
          defaultReminderTime: value
        }));
        break;
      case 'managerName':
        setManager(value);
        setProfileData((prevState) => ({
          ...prevState,
          Manager: value?.value
        }));
        break;
      case 'managerName':
        setManager(value);
        setProfileData((prevState) => ({
          ...prevState,
          Manager: value
        }));
        break;
      case 'timeZone':
        setTimeZone(value);
        setProfileData((prevState) => ({
          ...prevState,
          timezone: value
        }));
        break;

      default:
        break;
    }
  };

  /**
* function for filtering names based on names in manager dropdown
* @author Bhavana
*/
  const filterChangeForManager = async (event) => {
    const filteredList = filterData(event.filter, initialManagerList);
    setManagerList([...filteredList]);
  };

  /**
* function for filtering list based on name in timezone dropdown
* @author Bhavana
*/
  const filterChangeForTimezone = async (event) => {
    const filteredList = filterData(event.filter, initialTimeZone);
    setTimeZoneList([...filteredList]);
  };

  /**
* Fetches the live timezone and updates the timezone state in the organization data.
* @author Bhavana
*/
  const handleLiveTimeZone = async () => {
    const liveTimezone = await getLiveTimezone();
    setTimeZone(liveTimezone);
  };

  return (
    <div className="col-md-7">
      <div className="form-group">
        <label>{label.DISPLAY_NAME}</label>
        <sup className="text-danger">*</sup>
        <Input
          className="w-100"
          placeholder="Display Name"
          name="displayName"
          value={displayName}
          onChange={handleChange}
          maxLength={number.THIRTY_FIVE}
          id="rightprofilepanel-displayname"
        />
      </div>
      <div className="form-group">
        <label>{label.EMAIL}</label>
        <Input
          className="w-100"
          name="userEmail"
          value={userEmail}
          disabled
          id="rightprofilepanel-user-email"
        />
      </div>
      <div className="form-group">
        <label>{label.JOB_TITLE}</label>
        <Input
          className="w-100"
          placeholder="Job Title"
          name="jobTitle"
          value={jobTitle}
          onChange={handleChange}
          maxLength={number.THIRTY_FIVE}
          id="rightprofilepanel-job-title"
        />
      </div>
      <div className="form-group d-flex">
        <div className="w-50 pr-1">
          <label>{label.MY_TASK_CODE}</label>
          <sup className="text-danger">*</sup>
          <Input
            className="w-100"
            placeholder="My Task Code"
            name="taskCode"
            value={taskCode}
            onChange={handleChange}
            maxlength={number.TEN}
            id="rightprofilepanel-task-code"
          />
        </div>
        <div className="w-50 pl-1">
          <label>{label.MANAGER}</label>
          <DropDownList
            className="w-100"
            name="managerName"
            data={managerList}
            value={manager}
            textField="label"
            dataItemKey="value"
            onChange={handleChange}
            filterable={true}
            onFilterChange={filterChangeForManager}
            id="rightprofilepanel-manager-list"
          />
        </div>
      </div>
      <div className="form-group">
        <label>{label.TIME_ZONE}</label>
        <span className="pull-right cursor-pointer fs-1" title={label.USE_LIVE_LOCATION} onClick={handleLiveTimeZone} id="RightProfilePanel-handle-live-timezone">
          <Tooltip anchorElement="target" parentTitle={true} position="bottom">
            {icon.LOCATION}
          </Tooltip>
        </span>
        <DropDownList
          className="w-100"
          data={timeZoneList}
          value={timeZone}
          name="timeZone"
          onChange={handleChange}
          filterable={true}
          onFilterChange={filterChangeForTimezone}
          id="rightprofilepanel-timezone-dropdown"
        />
      </div>
      <div className="form-group">
        <label>{label.BOARD_NAME}</label>
        <Input
          className="w-100"
          placeholder="Personal Board Name"
          name="boardName"
          value={boardName}
          maxLength={number.THIRTY_FIVE}
          id="rightprofilepanel-personal-board"
          disabled
        />
      </div>
      <div className="form-group d-flex align-items-center">
        <label className="mr-2">{label.DEFAULT_REMINDER_TIME}</label>
        <Input
          className="w-50"
          placeholder="00:00"
          name="reminderTime"
          value={reminderTime}
          onChange={handleChange}
          type="time"
          id="rightprofilepanel-reminder-time"
        />
      </div>
    </div>
  );
};

export default RightProfilePanel;
