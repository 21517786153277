import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { number } from '../../config';
import { editorEntity } from '../../editor/editor.Constant';
import { projectClickAction } from '../../helper/common';
import SearchResultItem from './SearchResultItem';
import { saveClickHistory } from './overallsearch.service';
import { isPerfectMatch, taskClickAction } from './search.helper';

/**
* overall Search items List
* Parent Comp. - overallSeachPopup
* @author Himanshu
*/
const OverallSearchItems = (props) => {
  const { results, setShowPopup, entityName, searchText, selected, cursor, insertTaskChip } = props;
  const history = useHistory();
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch();

  /**
  * Enter button action
  */
  useEffect(() => {
    if ((entityName !== "Linked Task" || entityName !== editorEntity.TASK_DESC) && selected) searchClickAction(selected?.EntityTypeId, selected)
  }, [selected])


  /**
  * returns search result click action on the basis of entityType
  * @param {entity, clickedEntity}
  * @returns {function}
  */
  const searchClickAction = (entityTypeId, clickedEntity) => {
    setShowPopup(false);
    let payload = {
      userId: user.id,
      searchText: searchText
    }
    dispatch(saveClickHistory(payload))
    switch (entityTypeId) {
      case number.FOUR:
        return taskClickAction(clickedEntity);
      case number.THREE:
        return projectClickAction(clickedEntity, history);
    }
  }
  /**
   * Handle on click method for search result.
   * @param {*} entityResults
   * @param {*} item
   */
  const handleOnClick = (entityResults, item) => {
    if (entityName === editorEntity.TASK_DESC) {
      insertTaskChip(item)
      return
    }
    if (entityName === 'Linked Task') return;
    searchClickAction(entityResults?.Id, item);
  }
  /**
   * Get conditional class for each item
   * @param {Object} item
   * @param {Int} idx
   * @param {Int} index
   * @returns {String} className
   */
  const getItemClass = (item, idx, index) => {
    let className = 'single-result'
    if (item) {
      if (index === cursor?.parentIdx && idx === cursor?.childIdx) className += ' active-search-item';
      if (isPerfectMatch(item, searchText)) className += ' highlight';
    }
    return className;
  }
  return (
    <>
      {results && results?.map((entityResults, index) => {
        return <>
          {entityResults.searchResult?.length > number.ZERO &&
            <div key={entityResults.Id} className='entity-result-container'>
              {entityResults.Name && <div className='search-entity-heading'>{entityResults.Name}</div>}
              {entityResults.searchResult?.length > number.ZERO && entityResults.searchResult?.map((item, idx) => {
                return (
                  item && <SearchResultItem item={item} handleOnClick={handleOnClick} entityResults={entityResults} searchText={searchText} idx={idx} index={index} getItemClass={getItemClass} entityName={entityName} setShowPopup={setShowPopup} />
                )
              })}
            </div>}
        </>
      })}
    </>
  )
}
export default OverallSearchItems;