import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { kendo, label, notifyIcon } from "../../../../config";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";
import { addWorkflowElement, addWorkflowStages } from "../../../../shared/services/workflow.services";
import { validateInputLength } from "../../../../shared/validators/validator";
import { addLinkedAndSubtasks, addNewComments, addTask, addTaskPanelVisibiltyOptions, getTasks, newTaskAttachments } from "../../../Tasks/tasks.service";
import { addNewCommentFiles } from "../../TaskSidebarContent/Comments/comments.service";
import { getCurrentProject } from "../../sidebar.helper";
import { alertNotification } from "../../../../actions/alertNotification";
import { getNotification } from "../../../../utils/common";
import { resetSidebarReducer, setIsTaskSaved, setShowTaskSidebar, resetRecurrence } from "../../../../actions/taskSidebar";
import { resetLinkTaskForNewTask } from "../../../../actions/linkedTasks";

export const useSaveTask = () => {
  const dispatch = useDispatch();
  const { subTask, linkTask } = useSelector((state) => state.linkedTasks);
  const { user } = useSelector((state) => state.auth);
  const { positionId } = useSelector((state) => state.userPosition);
  const { allAttachments } = useSelector((state) => state.attachments);
  const { allComments } = useSelector((state) => state.comments);
  const { workflowStages } = useSelector((state) => state.taskWorkflowDetails);
  const { cloneTask } = useSelector((state) => state.cloneTaskDetails);
  const { newTaskData, taskUrl, isDependent, flowElements, showAttachments, showDescription, showLinkedTasksSubTasks, showComments, isNewTask, showSimplifiedDetail, showDescPopup } = useSelector(
    (state) => state.taskSidebar
  );



  const saveFlowElements = (taskId) => {
    if (!flowElements.length || !taskId) return;
    dispatch(addWorkflowElement({ workflowElements: flowElements, taskId, isNewTask }));
  };

  const saveTaskPanelVisibiltyOptions = (taskId) => {
    if (!taskId) return;
    addTaskPanelVisibiltyOptions({
      taskId: taskId,
      showDescription,
      showAttachments,
      showChildSubTasks: showLinkedTasksSubTasks,
      showComments,
      showSimplifiedDetail,
      showTaskDescPopup: showDescPopup.status,
    });
  };

  const saveWorkflowStages = (taskId) => {
    if (!workflowStages.length || !taskId) return;
    dispatch(addWorkflowStages({ taskId, stages: workflowStages }));
  };

  async function getBlobFromURL(blobURL) {
    const response = await fetch(blobURL);
    const blob = await response.blob();
    return blob;
  }

  function blobToFile(blob, fileName) {
    // Create a File from the Blob
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now()
    });
    return file;
  }

  async function convertBlobURLToFile(blobURL, fileName) {
    try {
      // Fetch the Blob from the Blob URL
      const blob = await getBlobFromURL(blobURL);

      // Convert the Blob to a File
      const file = blobToFile(blob, fileName);

      return file;
    } catch (error) {
      console.error('Error converting Blob URL to File:', error);
    }
  }

  const getCommentsArrays = (comments) => {
    const result = comments.reduce(
      (acc, { Id, CommentImages, NewCommentImages, ...rest }, index) => {
        const uniqueId = `unique-${index + 1}`;

        // Add object to the first array without commentImages and with uniqueId
        acc.commentsToBeSaved.push({ ...rest, uniqueId });

        // Split commentImages by comma and add each as a separate object to the second array
        NewCommentImages?.forEach(async (image) => {
          const file = await convertBlobURLToFile(image.imageUrl, image.fileName);
          await acc.commentFiles.push(Object.assign(file, { uniqueId }));
        });

        return acc;
      },
      { commentsToBeSaved: [], commentImages: [], commentFiles: [] }
    );

    return result;
  };

  const saveTaskComments = async (taskId) => {
    if (!allComments.length || !taskId) return;
    const { commentsToBeSaved, commentFiles } = getCommentsArrays(allComments);
    const addedComments = await dispatch(addNewComments({ taskId: taskId, comments: commentsToBeSaved }));
    if (addedComments?.length > 0) {
      const { filesToBeSaved } = addedComments?.reduce((acc, comment) => {
        acc.filesToBeSaved.push({ CommentId: comment.CommentId, files: commentFiles?.filter((file) => file.uniqueId === comment.UniqueId) });
        return acc;
      },
        { filesToBeSaved: [] }
      );

      filesToBeSaved?.forEach(({ CommentId, files }) => {
        if (files.length) {
          const fileSavePayload = { commentId: CommentId, files };
          const formData = createFormData();
          appendFieldsFromObject(formData, fileSavePayload);
          dispatch(addNewCommentFiles(formData));
        }
      });
    }
  };

  const saveNewTaskAttachments = async (taskId) => {
    if (!allAttachments?.length || !taskId) return;
    const attachments = [...allAttachments];
    const attachmentsToBeSave = [];
    const copyAttachments = [];
    attachments.forEach((attachment) => (attachment?.IsCopyAttachment ? copyAttachments.push(attachment) : attachmentsToBeSave.push(attachment)));
    let fileAttachments = [];
    fileAttachments = await Promise.all(
      attachmentsToBeSave.map((attachment) => {
        const { AttachmentLink, AttachmentName } = attachment;
        return convertBlobURLToFile(AttachmentLink, AttachmentName);
      })
    );

    const payload = { entityName: "Tasks", entityId: taskId, files: fileAttachments, copyAttachments: JSON.stringify(copyAttachments) };
    const formData = createFormData();
    appendFieldsFromObject(formData, payload);
    dispatch(newTaskAttachments(formData));
  };

  const saveTaskHandler = async () => {
    if (!isEmpty(newTaskData.name?.trim()) && validateInputLength(newTaskData.name?.trim(), kendo.INPUT_MIN_LENGTH, kendo.TASK_INPUT_LENGTH)) {
      const payload = {
        ...newTaskData,
        ...{
          companyId: user.companyId,
          currentProject: getCurrentProject(true),
          creator: user?.id,
          cloneTaskId: cloneTask?.taskId,
          taskUrl,
          isDependent,
          flowElementsCount: flowElements?.length,
          positionId,
        },
      };
      let response = await addTask(payload);
      if (response.EntityId) {
        isNewTask && (subTask.length || linkTask?.childTask?.length || linkTask?.parentTask?.length || linkTask?.relatedTo?.length) && handleLinkedTasks(response.EntityId);
        saveFlowElements(response.EntityId);
        saveTaskPanelVisibiltyOptions(response.EntityId);
        saveWorkflowStages(response.EntityId);
        await saveTaskComments(response.EntityId);
        saveNewTaskAttachments(response.EntityId);
        dispatch(setIsTaskSaved(true))
        dispatch(setShowTaskSidebar(false));
        dispatch(resetRecurrence())
        dispatch(resetSidebarReducer())
        dispatch(resetLinkTaskForNewTask())
        getTasks(null, true);
        const taskPayload = {
          taskHistory: response.taskHistoryId,
          taskId: response.EntityId
        }
        dispatch(alertNotification(true, `${response.projectTaskId} ${label.TASK_CREATED}`, notifyIcon.SUCCESS_ICON, taskPayload));
      } else {
        getNotification(label.ERROR_OCCURED, notifyIcon.ERROR_ICON);
      }
    }
  };

  const handleLinkedTasks = (taskId) => {
    let linkTasks = [];
    let subTasks = [];
    linkTasks = linkTasks.concat(linkTask?.childTask)
    linkTasks = linkTasks.concat(linkTask?.parentTask)
    linkTasks = linkTasks.concat(linkTask?.relatedTo)
    subTasks = subTask;
    subTasks = subTasks.map(task => ({
      subtaskName: task?.Name,
      dueDate: task?.DueDate,
      projectId: newTaskData?.project,
      isCompleted: task?.IsCompleted
  }));
    linkTasks = linkTasks.map(task => ({
      workflowId: task?.WorkflowId,
      relationId: task?.RelationId,
      childTaskId: task?.ChildTaskId,
      linkedTaskId: task?.LinkTaskId
  }));
    let payload = {
        taskId: taskId,
        linkTasks: JSON.stringify(linkTasks),
        subTasks: JSON.stringify(subTasks),
    }
    payload && dispatch(addLinkedAndSubtasks(payload))
  }

  return { saveTaskHandler, convertBlobURLToFile };
};
