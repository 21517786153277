import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { icon, number, tooltip } from "../../../config";
import { splitString } from "../../../utils";
import { getCurrentActiveProject } from "../sidebar.common";
import { getBreadCrumbList } from "../sidebar.helper";
import DetailedProjectTrails from "./DetailedProjectTrails";
import HeaderActions from "./HeaderActions";

/**
 * HeaderTitle component handles project trails logic
 * @props {}
 */
const HeaderTitle = ({
  defaultDetails,
  activeUserList,
  currentFlowSetter,
  breadcrumbs,
  setBreadcrumbs,
  currentFlow,
}) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [showLastWedges, setShowLastWedges] = React.useState(false);
  const projectTrailsRef = React.useRef(null);
  const { isNewTask, task, flowElements } = useSelector(
    (state) => state.taskSidebar
  );
  const { user } = useSelector((state) => state.auth);
  const [openFlow, setOpenFlow] = React.useState(null);

  /**
   * used to set these values setIsSelected, currentFlowSetter, setCurrentActiveView, setBreadcrumbs on first render
   * @author {Himanshu Negi}
   */

  useEffect(() => {
    if (!isNewTask) {
      const {
        currentProject: currentActiveProject,
        currentProjectIndex: isCurrent,
      } = getCurrentActiveProject(flowElements);
      const breadcrumbsList = getBreadCrumbList(
        flowElements,
        currentActiveProject,
        isCurrent
      );
      const activeFlowIndex = breadcrumbsList?.findLastIndex(
        (item) => item.id === currentActiveProject?.id
      );
      currentFlowSetter(breadcrumbsList[activeFlowIndex]);
      setBreadcrumbs(breadcrumbsList);
      setShowLastWedges(
        flowElements.length > number.THREE &&
        flowElements[flowElements.length - number.ONE] !==
        breadcrumbsList[breadcrumbsList.length - number.ONE]
      );
    }
  }, [flowElements, isNewTask]);

  /**
   * gives key value pairs of project codes and their respective task delegators
   * @returns {Object}
   */

  /**
   * handlea ProjectTrails Popup
   */
  const handleProjectTrailsPopup = (flowId) => {
    setShowPopup(!showPopup);
    projectTrailsRef.current = document.getElementById(
      `task-sidebar-breadcrumb-${flowId}`
    );
    setOpenFlow(flowId);
  };

  const CustomTooltip = (props) => {
    const name = splitString(props.title, "-|")[number.ZERO];
    const email = splitString(props.title, "-|")[number.ONE];
    return (
      <>
        {splitString(props.title, "-|").length == number.TWO && (
          <div>
            <div>{name}</div>
            <div> {email}</div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Tooltip
        className="pre-line"
        anchorElement="target"
        position="bottom"
        parentTitle="true"
      >
        {!isNewTask && (
          <span className="header-projecttrais">
            {task && (task.ProjectCode) && (
              <div>
                <ul className="wizard d-flex text-center justify-content-center">
                  {breadcrumbs &&
                    breadcrumbs?.map((flow, index) => {
                      let currentProject =
                        defaultDetails?.allProjectsList?.find(
                          (project) =>
                            project?.ProjectId == flow?.assignmentProjectId
                        );
                      const projectCode = currentProject?.ProjectCode;
                      const ProjectName = currentProject?.ProjectName;

                      return (
                        <React.Fragment key={index}>
                          {
                            <li
                              id={`task-sidebar-breadcrumb-${flow?.id}`}
                              className={`wizard-item h-100 position-static d-inline-flex justify-content-center align-items-center cursor-pointer ${currentFlow?.id == flow?.id
                                ? "wizard-active-item"
                                : ""
                                }`}
                              onClick={() => handleProjectTrailsPopup(flow?.id)}
                              ref={
                                openFlow == flow?.id ? projectTrailsRef : null
                              }
                            >
                              {showPopup && openFlow == flow?.id && (
                                <DetailedProjectTrails
                                  anchor={projectTrailsRef}
                                  setShowPopup={setShowPopup}
                                />
                              )}
                              {!!flow.isApproval && (
                                <span className="verified-icon position-absolute" title={tooltip.APPROVAL}>
                                  {icon.APPROVAL_ICON}
                                </span>
                              )}
                              <span className="wizard-inner d-flex flex-column">
                                <span
                                  className="wizard-title font-weight-semi-bold overflow-hidden"
                                  title={`${ProjectName}`}
                                >
                                  {projectCode}
                                </span>
                              </span>
                            </li>
                          }
                        </React.Fragment>
                      );
                    })}
                  {showLastWedges &&
                    Array(number.THREE)
                      .fill()
                      .map((_, index) => (
                        <li
                          key={index}
                          className="wizard-item h-100 position-static d-inline-flex justify-content-center align-items-center last-wizard"
                        ></li>
                      ))}
                </ul>
              </div>
            )}
          </span>
        )}
      </Tooltip>
      <Tooltip
        anchorElement="target"
        position="bottom"
        parentTitle="true"
        content={(props) => CustomTooltip(props)}
      >
        <div className="active-user-list d-flex align-items-center justify-content-center">
          {activeUserList?.map((usr, index) => (
            <React.Fragment key={index}>
              {usr.value != user.id && (
                <span
                  className="active-user-list-item border-radius-50 cursor-pointer"
                  title={usr?.label + "-|" + usr?.Email}
                >
                  {usr.PhotoLink ? (
                    <img alt={usr.label} src={usr.PhotoLink} className="border-radius-50 object-fit-cover"></img>
                  ) : (
                    icon.ACCOUNT_CIRCLE
                  )}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </Tooltip>
      <HeaderActions />
    </>
  );
};
/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */
function mapStateToProps(state) {
  return {
    auth: state.auth,
    defaultDetails: state.tasks.defaultDetails,
    allTasks: state.tasks.allTasks,
  };
}
export default connect(mapStateToProps, null)(HeaderTitle);