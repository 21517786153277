import { Popup } from '@progress/kendo-react-popup';
import React, { useCallback, useEffect } from 'react';
import SmartChip from './SmartChip';
import UpdateChip from './UpdateChip';
import HyperLinkPopup from './HyperLinkPopup';
import { number } from '../../config';
import { useEditorContext } from '../editor.Context';
import OverallSearchPopup from '../../components/OverallSearch/OverallSearchPopup';
import { generateUniqueId } from '../editor.helper';
import { useSelector } from 'react-redux';
import { defaultUserChip, editorEntity } from '../editor.Constant';
import UpdateIFrame from './UpdateIFrame';

/**
* Popup  for Editor
* PC KendoEditor
* @author Shivam Mishra
*/
const EditorPopup = ({ insertNonEditable,
    updateNonEditable,
    editorRef,
    showEditIframe,
    editableRef,
    acknowledgeMailEditor }) => {
    const editorPopupRef = React.useRef();
    const ref = React.useRef();
    const [assigneeList, setAssigneeList] = React.useState([]);
    const [isInside, setIsInside] = React.useState(false);
    const [iframePopupPosition] = React.useState({
        anchorAlign: { horizontal: 'left', vertical: 'top' },
        popupAlign: { horizontal: 'left', vertical: 'bottom' }
    });

    const [HyperLinkPopupPosition] = React.useState({
        anchorAlign: { horizontal: 'center', vertical: 'bottom' },
        popupAlign: { horizontal: 'center', vertical: 'top' }
    });


    const editorState = useEditorContext();
    const { user } = useSelector((state) => state.auth);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const { visible, setVisible, url, selectedLink, overallSearchRef,
        taskInfo, showInputPopup, setInputPopup, smartChipTarget,
        editChip, showEditChip, setShowEditChip, smartChipId,
        showTaskSearchPopup, setTaskSearchPopup, setTempChipId, editIframe, editIframeId
    } = editorState;

    /**
    *useffect to hide a popup.
    * @author Shivam Mishra
    */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editorPopupRef.current && !editorPopupRef.current.contains(event.target)) {
                hidePopup()
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editorPopupRef]);

    /**
    * useffect to set assignee list from default details.
    */
    useEffect(() => {
        defaultDetails?.assigneeList && setAssigneeList([defaultUserChip(acknowledgeMailEditor), ...defaultDetails?.assigneeList])
    },
        [defaultDetails?.assigneeList, showInputPopup])

    /**
    *Function to hide a popup.
    * @author Shivam Mishra
    */
    const hidePopup = useCallback(() => {
        setShowEditChip(false);
        setInputPopup(false);
        setVisible(false);
        setTaskSearchPopup(false);
        setTempChipId(null);
    }, []);

    /**
 * Inserts a task chip into the editor.
 * @param {object} taskDetail - The task object containing Name, Id, and Code.
 * @returns {Promise<void>} A Promise that resolves once the task chip is inserted.
 * @author Shivam Mishra
 */
    const insertTaskChip = async (taskDetail) => {
        //  insertNonEditable
        const { Name, Id, Code } = taskDetail;
        const { view } = editorRef.current.state;
        view.focus();
        const label = `${Code} : ${Name}`
        const id = await generateUniqueId(user.id, "task", Id);
        insertNonEditable(label, id, "task-chip");
        setTaskSearchPopup(false);
    }

    /**
*Function to hide a popup on escape press.
* @author Shivam Mishra
*/
    const onKeyDown = useCallback((ev) => {
        if (ev.key === 'Escape') {
            hidePopup();
        }
    }, []);
    /**
    *Checks if the mouse cursor is inside the editor's content element.
    *Updates the `isInside` state based on the cursor's position relative to the element's bounding box.
    *@author Shivam Mishra
    */
    const checkIfMouseInside = (event) => {
        const dialogIndexElement = document.getElementsByClassName("dialog-index");
        if (!editorRef.current || dialogIndexElement?.length) return;
        if (document.getElementById("excalidraw-dialog")) return;

        const contentElement = editorRef.current._contentElement;
        const rect = contentElement.getBoundingClientRect();
        const { clientX: mouseX, clientY: mouseY } = event;

        const inside =
            mouseX >= rect.left &&
            mouseX <= rect.right &&
            mouseY >= rect.top &&
            mouseY <= rect.bottom;

        setIsInside(inside);
    };

    /**
    *useffect to show edit iframe popup when user is inside the description box
    * @author Shivam Mishra
    */
    useEffect(() => {
        const handleMouseMove = (event) => checkIfMouseInside(event);

        // Add mouse move event listener
        document.addEventListener('mousemove', handleMouseMove);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>

            {visible &&
                <Popup anchor={selectedLink} show={visible} id="editor-update-popup" ref={ref}
                    anchorAlign={HyperLinkPopupPosition.anchorAlign}
                    popupAlign={HyperLinkPopupPosition.popupAlign}
                    margin={{ vertical: number.ZERO }}
                >
                    <div
                        id="editor-popup-handle-hyperlink"
                        ref={editorPopupRef}
                        className='container pt-2 pb-2'
                    >
                        <HyperLinkPopup url={url} selectedLink={selectedLink} setVisible={setVisible} visible={visible} editorRef={editorRef} taskInfo={taskInfo} editableRef={editableRef} />
                    </div>
                </Popup>
            }
            <Popup
                show={(showInputPopup || showEditChip)}
                anchor={(showInputPopup ? smartChipTarget : editChip)}
                collision={{
                    horizontal: "fit",
                    vertical: "flip",
                }}
            >
                {
                    assigneeList &&
                    <div ref={editorPopupRef} id={(showInputPopup ? "editor-popup-smart-chip" : "editor-popup-edit-chip")} onKeyDown={onKeyDown}>
                        {(showInputPopup ? <SmartChip updateNonEditable={updateNonEditable} assigneeList={assigneeList} setAssigneeList={setAssigneeList} /> : <UpdateChip smartChipId={smartChipId} setShowEditChip={setShowEditChip} assigneeList={assigneeList} setAssigneeList={setAssigneeList} />)}
                    </div>
                }
            </Popup>
            {<div className='add-button-wrapper' onKeyDown={onKeyDown}>
                {showTaskSearchPopup && <OverallSearchPopup anchor={overallSearchRef} setShowPopup={setTaskSearchPopup} entityName={editorEntity.TASK_DESC} insertTaskChip={insertTaskChip} />}
            </div>
            }
            <Popup anchor={document.getElementById(editIframeId)} show={editIframeId && !showEditIframe && isInside} id="editor-update-popup" ref={ref}
                anchorAlign={iframePopupPosition.anchorAlign}
                popupAlign={iframePopupPosition.popupAlign}
            >
                <div
                    id="editor-iframe-popup"
                    ref={editorPopupRef}
                    className='container pt-2 pb-2'
                >
                    <UpdateIFrame editorRef={editorRef} />
                </div>
            </Popup>
        </>
    );
};

export default EditorPopup;