import { GET_PROJECTS, GET_USER_PROJECTS, GET_SECONDARY_PROJECTS, UPDATE_PROJECT_DESC, STORE_PROJECT_STREAMS, STORE_EMOJI, UPDATE_STREAM_EMOJI, ADD_NEW_STREAM, IS_LAST_STREAM, CLEAR_STREAMS, STREAMS_MARK_AS_READ, ADD_PROJECT, UPDATE_PROJECT, FILTER_DATA_BY_PROJECT_ID, CONTEXT_SEARCH } from "../actions/action-types";
import { number } from "../config/index";
import { isDateValid } from "../shared/validators/validator";

const initialState = {
  description: "",
  data: [],
  projectFilters: "",
  queuePrivacy: [],
  streams: [],
  emojiIcons: [],
  isLastStream: false,
  contextSearch :" "
}

/**
 * Updates objects within object 
 * @param {object} Stream State
 * @param {Object} object to be updated  
 * @returns 
 */

export const updatedProjectStreamEmoji = (data, payload) => {
  const { count, isMine, reaction, streamId } = payload;
  const streamToUpdate = data.find(obj => obj.Id === streamId);
  if (streamToUpdate) {
    const emojiToUpdate = streamToUpdate.emojis.find(innerObj => innerObj.emoji === reaction);
    if (emojiToUpdate) {
      emojiToUpdate.count = emojiToUpdate.count + count;
      emojiToUpdate.isMine = isMine;
    } else {
      const newEmojiObject = { emoji: reaction, count: count, isMine: isMine };
      streamToUpdate.emojis.push(newEmojiObject);
    }
  }
  return data;
}



/**
 * Updates objects within object 
 * @param {object} Stream State
 * @param {Object} object to be updated or added 
 * @returns 
 */
export const updatedStreamState = (data, payloadStream) => {

  var newStreamState = []
  var index = (data?.findIndex((i) => i.Id == payloadStream.Id))
  if (index != number.MINUS_ONE) {
    payloadStream.emojis = data[index]?.emojis
    data[index] = payloadStream
    newStreamState = data
  } else {
    newStreamState = data
    payloadStream.emojis = []
    newStreamState.unshift(payloadStream)
  }
  return newStreamState.sort((b, a) => isDateValid(a?.DateAndTime) && isDateValid(b?.DateAndTime) &&
    new Date(a?.DateAndTime)?.getTime() - new Date(b?.DateAndTime)?.getTime());

}

export const updateRead = (data, streamId) => {
  return data.map((item) => {
    if (item.Id === streamId) {
      return { ...item, IsRead: 1 };
    }
    return item;
  });
}

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const allProjects = (state = initialState, action) => {
  const { data, projectFilters, queuePrivacy } = action.payload ?? {}
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        data: data,
        projectFilters: projectFilters && projectFilters,
        queuePrivacy: queuePrivacy?.length && queuePrivacy
      };
    case GET_USER_PROJECTS:
      return action.payload;
    case GET_SECONDARY_PROJECTS:
      return action.payload;
    case STORE_PROJECT_STREAMS:
      return {
        ...state,
        streams: [...state?.streams || [], ...action.payload]
      }
    case STORE_EMOJI:
      return {
        ...state,
        emojiIcons: action.payload
      }
    case UPDATE_PROJECT_DESC:
      return {
        ...state,
        description: action.payload || ""
      }
    case UPDATE_STREAM_EMOJI:
      let streams = state.streams;
      return {
        ...state,
        streams: updatedProjectStreamEmoji(streams, action.payload)
      }
    case ADD_NEW_STREAM:
      let oldStream = state.streams;
      return {
        ...state,
        streams: [...updatedStreamState(oldStream, action.payload)]
      }
    case IS_LAST_STREAM:
      return {
        ...state,
        isLastStream: action.payload
      }
    case STREAMS_MARK_AS_READ: {
      let streams = state.streams
      return {
        ...state,
        streams: updateRead(streams, action.payload)
      }
    }
    case CLEAR_STREAMS:
      return {
        ...state,
        description: "",
        streams: [],
        emojiIcons: [],
        isLastStream: false
      }
    case ADD_PROJECT:
      return {
        ...state,
        data: [...state.data, action.payload]
      };

    case UPDATE_PROJECT:
      return {
        ...state,
        data: state.data.map(project => {
          if (project.ProjectId === action.payload.ProjectId) {
            const updatedProject = { ...project };
            for (const key in action.payload) {
              if (action.payload.hasOwnProperty(key) && action.payload[key] !== undefined) {
                updatedProject[key] = action.payload[key];
              }
            }
            return updatedProject;
          }
          return project;
        })
      };
    case FILTER_DATA_BY_PROJECT_ID:
      return {
        ...state,
        data: state.data.filter(item => item.ProjectId !== action.payload.ProjectId),
      }
case CONTEXT_SEARCH :
  return {
    ...state,
    contextSearch: action.payload 
  }
    default:
      return state;
  }
};

export default allProjects;
