import React, { useEffect, useState } from "react";
import { label } from "../../config";
import { Tooltip } from "@progress/kendo-react-tooltip";


const Chatbot = ({ userId, organizationId, baseUrl }) => {
  const [isChatOpen, setIsChatOpen] = useState(false); // State to track if the chat is open
  const iframeSrc = `https://chatbot-dev.drutas.dev/chatbot/?userId=${userId}&organizationId=${organizationId}&baseUrl=${baseUrl}`;
  // Listen for messages from the iframe
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && typeof event.data.chatOpen !== "undefined") {
        setIsChatOpen(event.data.chatOpen); // Update chat status based on iframe message
      }
    };
    window.addEventListener("message", handleMessage);
    // Cleanup event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <Tooltip anchorElement='target' parentTitle={true} position='right'>
    <div >
     <iframe
        src={iframeSrc}
        style={{
          width: isChatOpen ? "750px" : "46px",
          height: isChatOpen ? "690px" : "46px",
          border: "none",
          transition: "all 0.3s ease-in-out",
          position: isChatOpen ? "fixed" : "absolute",
          bottom: isChatOpen ? "40px" : "37px",
          left: isChatOpen ? "75px" : "10px",
        }}
        title={label.HELP_SUPPORTICON}
      />
   
    </div>
    </Tooltip>
  );
};
export default Chatbot;