import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Popup } from "@progress/kendo-react-popup";
import { setNewMyTasks } from "../../../../actions/task";
import { resetRecurrence, setShowTaskSidebar, setTaskOnBlur } from "../../../../actions/taskSidebar";
import { icon, label, number, route } from "../../../../config";
import { usePopup } from "../../../../helper/commonHooks";
import { onBlur, onFocus, onOpen } from "../../../../utils/kendo";
import { useKanbanUpdates } from "../../../KanbanView/hooks/kanban.hooks";
import { setDisabledForStage } from "../../../TaskSidebar/sidebar.helper";
import { setStageName } from "../../../Tasks/tasks.helper";
import { completeTask, handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import "./loader.scss";

const TaskStages = ({ defaultDetails }) => {
  const { task, isNewTask, isDependent, stages: taskStages } = useSelector((state) => state.taskSidebar);
  const { allTasks } = useSelector((state) => state.tasks);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.stageId);
  const { recurrenceDetails } = useSelector((state) => state.recurrence);
  const { id } = useSelector((state) => state.auth.user),
    [stageDropdownData, setStageDropdownData] = useState(),
    [stage, setStage] = useState({}),
    [isDisabled, setIsDisabled] = useState();
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const { show: dependencyPopup, setShow: setDependencyPopup, anchor } = usePopup();
  const dispatch = useDispatch();
  let incompleteDependentTasks = task.inCompleteSubtaskCount + task.inCompleteChildtaskCount;
  const { workflowBasicDetails, workflowStages } = useSelector((state) => state.taskWorkflowDetails);
  const { pathname } = useLocation();
  const contentRef = useRef();
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const { selectedView } = useSelector((state) => state.tasks);

  const { updateKanbanStage } = useKanbanUpdates();

  //redux stage update when new task is being created
  // author: Himanshi
  useEffect(() => {
    if (
      stage?.value &&
      stage?.value !== task.StageId &&
      !(stage?.value === stageDropdownData[stageDropdownData?.length - number.ONE].value && task?.IsDependent && incompleteDependentTasks) &&
      isNewTask
    ) {
      handleTaskKeyUpdate(isNewTask, "stageId", stage?.value);
      handleTaskKeyUpdate(isNewTask, "currentStage", stage?.value);
    }
  }, [stage]);

  // sets incoming stage of workflow as the default stage
  useEffect(() => {
    setStage(stageDropdownData?.find((stages) => stages.value == workflowBasicDetails?.IncomingStage));
  }, [workflowBasicDetails, stageDropdownData]);

  // Update task
  useEffect(() => {
    if (task.taskId) {
      setStage(stageDropdownData?.find((stages) => stages?.value == task?.StageId));
    }
  }, [task?.taskId, stageDropdownData, task?.StageId]);

  // First render
  useEffect(() => {
    if (defaultDetails) {
      setDisabledForStage(task, setIsDisabled, matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH }));
      const stagesData = isNewTask ? workflowStages?.filter((stage) => !stage.isHidden) : taskStages?.filter((stage) => !stage.isHidden);
      setStageDropdownData(stagesData ? stagesData : []);
    }
  }, [isNewTask, task.taskId, workflowStages, taskStages]);

  const handleStageChange = async (event) => {
    const payload = {
      taskHistoryId: task.taskHistoryId,
      taskId: task.Id ? task.Id : task.taskId,
      userId: id,
    };
    const internalAssignee = taskStages?.find((item) => item?.value == event.target.value?.value)?.defaultAssignee;
    if (task?.taskId) {
      if (internalAssignee) dispatch(setTaskOnBlur({ key: "Assignee", value: internalAssignee }));
    }
    let currentProject = defaultDetails.allProjectsList?.find((p) => p.ProjectId === task?.CurrentProject);
    if (currentProject?.FinalStage == event?.target.value.value && recurrenceDetails?.Id) {
      await completeTask(payload);
      dispatch(setShowTaskSidebar({ showTaskSidebar: false }));
      dispatch(resetRecurrence());
    }
    const selectedStage = event?.target.value;
    if (selectedStage?.value === stageDropdownData[stageDropdownData?.length - number.ONE].value && isDependent && incompleteDependentTasks) {
      setDependencyPopup(true);
    }
    setStage(selectedStage);
    if (selectedView === label.KANBAN) updateKanbanStage(task?.taskId, selectedStage?.value, internalAssignee ? internalAssignee : null);
    updateStageInDatabase(selectedStage);
  };

  const hideOnBlur = () => {
    setStage(stageDropdownData?.find((stage) => stage?.value === task?.StageId));
    setDependencyPopup(false);
  };

  /**
   * Updates the stage and handles task key update.
   *
   * @param {boolean} isNewTask - Indicates if it is a new task.
   * @param {string} stageId - The ID of the stage.
   * @returns {void}
   * @author Himanshi Chawla
   */
  const updateStage = () => {
    handleTaskKeyUpdate(isNewTask, "stageId", stage?.value);
    setDependencyPopup(false);
  };

  /**
   * Updates the stage in the redux and database when stage is updated from dropdown.
   *
   * @param {Object} updatedStage - The updated stage object.
   * @author Himanshi Chawla
   */
  const updateStageInDatabase = (updatedStage) => {
    const payload = setStageName(allTasks, updatedStage, task?.taskId);
    dispatch(setNewMyTasks(payload));
    if (
      updatedStage?.value &&
      updatedStage?.value !== task.currentStage &&
      !(updatedStage?.value === stageDropdownData[stageDropdownData?.length - number.ONE].value && task?.IsDependent && incompleteDependentTasks && !isDisabled)
    ) {
      handleTaskKeyUpdate(isNewTask, "stageId", updatedStage?.value);
      handleTaskKeyUpdate(isNewTask, "currentStage", updatedStage?.value);

      const currentStageDefaultAssignee = taskStages?.find((stage) => stage?.value === updatedStage?.value)?.defaultAssignee;
      if (currentStageDefaultAssignee) handleTaskKeyUpdate(isNewTask, "assignedBy", id);
    }
  };

  return (
    <div ref={contentRef} className={`form-group ${showTaskDetailPanel && !isNewTask ? "form-group col-lg-5 col-md-5 col-sm-5" : "form-group col-lg-4 col-md-4 col-sm-4"}`}>
      {showTaskDetailPanel && !isNewTask ? "" : <label htmlFor='task-stages-dropdown'>{label.CURRENT_STAGE}</label>}
      <DropDownList
        id='task-stages-dropdown'
        disabled={isDisabled || loader}
        onChange={handleStageChange}
        value={stage}
        data={stageDropdownData}
        textField={"label"}
        popupSettings={{ width: "200px", appendTo: contentRef.current }}
      />
      {loader && <div className='preloader loader-wrap'></div>}

      <span ref={anchor}></span>
      {task?.IsDependent !== number.ZERO && dependencyPopup && (
        <Popup show={true} anchor={anchor.current} onOpen={() => onOpen(deleteRef)} className='dependency-popup'>
          <div
            className='dependency-popup-inner d-flex align-items-center justify-content-between'
            tabIndex={number.ZERO}
            ref={deleteRef}
            onFocus={() => onFocus(blurTimeoutRef)}
            onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
            <p className='pl-2 pr-2 mb-0'>{label.DEPENDENCY}</p>
            <span className='cursor-pointer red d-flex align-items-center' onClick={hideOnBlur}>
              {icon.CLOSE}
            </span>
            <span className='cursor-pointer green d-flex align-items-center' onClick={updateStage}>
              {icon.CHECKBOX}
            </span>
          </div>
        </Popup>
      )}
    </div>
  );
};

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
  return {
    auth: state.auth,
    defaultDetails: state.tasks.defaultDetails,
    allTasks: state.tasks,
    task: state.taskSidebar.task,
  };
}

export default connect(mapStateToProps, null)(TaskStages);
