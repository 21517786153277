import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import OriginatorView from './OriginatorView';
import './approvals.scss'
import { sanitizeHtmlString } from '../../../../helper/common';
import { icon, label, number } from '../../../../config';
import ApprovalRevokeIcon from './ApprovalRevokeIcon';
import ApprovalLogsDeleteIcon from './ApprovalLogsDeleteIcon';
import { getApprovalLogDate } from './approval.helper';
import { Tooltip } from '@progress/kendo-react-tooltip';
import ApprovalNote from './ApprovalNote';

/**
 * Approval Component, it is rendered in TaskSidebrContent component
 * @returns JSX
 */
const Approvals = () => {
  const [approvalLog, setApprovalLog] = useState(null);
  const { task, approvalLogs } = useSelector(store => store.taskSidebar);
  const { user } = useSelector((state) => state.auth);
  const { defaultDetails } = useSelector((store) => store.tasks);

  const [showApprovalLogs, setShowApprovalLogs] = useState(false)
  /**
   * latest approval log
   * @author Himanshu Negi
   */
  useEffect(() => {
    setApprovalLog(approvalLogs[approvalLogs?.length - number.ONE])
  }, [approvalLogs]);

  const status = useMemo(() => {
    return defaultDetails?.approvalStatus?.find((action) => action.StatusId === approvalLog?.ApprovalStatus);
  }, [approvalLog, defaultDetails?.approvalStatus])

  /**
  * Returns index position for rendering approval log delete icon
  * @author Himanshu Negi
  */
  const showDeleteIcon = useMemo(() => {
    const showIcon = +task?.approvalAssignedBy === +user.id && ![number.TWO, number.THREE, number.SEVEN, number.EIGHT].includes(approvalLog?.ApprovalStatus);
    return showIcon;
  }, [approvalLog, task?.approvalAssignedBy]);

  /**
   * Returns index position for rendering approval revoke icon
   * @author Himanshu Negi
   */
  const showRevokeIcon = useMemo(() => {
    return (approvalLog?.ApprovalStatus === number.TWO && task?.approvalAssignedBy === user?.id);
  }, [approvalLog, task?.approvalAssignedBy]);

  /**
   * sets more approval logs
   * @author Pragun Gandotra
   */
  const showMoreLogs = () => {
    setShowApprovalLogs(true)
  }

  /**
   * sets only one approval log
   * @author Pragun Gandotra
   */
  const showLessLogs = () => {
    setShowApprovalLogs(false)
  }
  return (
    <React.Fragment>
      <section className='py-2'>
        <div className="approval-originator-container">
          <div className="approval-originator-logs position-relative overflow-hidden">
            <div className="approval-originator-timeline"></div>
            <div className="approval-logs-details">
              <Tooltip position='bottom' anchorElement="target" parentTitle={true}>
                <div className="approval-originator-log">
                  <div className="approval-originator-logs-text">
                    <span className="approval-originator-icon rounded-circle d-flex justify-content-center align-items-center">
                      {icon[status?.StatusIcon]}
                    </span>
                    <p className="w-100">
                      <div className="approval-originator-activity" dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(approvalLog?.Activity) }}></div>
                      <div>
                        <span className="approval-originator-date-time">
                          {getApprovalLogDate(approvalLog?.CreatedAt)}
                        </span>
                        <span className="approval-logs-icons">
                          {showDeleteIcon && (<ApprovalLogsDeleteIcon logId={approvalLog?.Id} approvalStatus={approvalLog?.ApprovalStatus} />)}
                          {showRevokeIcon && (<ApprovalRevokeIcon />)}
                          {approvalLog?.Comments && <ApprovalNote logs={approvalLog} />}
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </Tooltip>
            </div> 
          </div>
          {!showApprovalLogs && <div className='see-morelogs-div' onClick={showMoreLogs}>{label.SHOW_MORE_LOGS}</div>}  
        </div>

        {showApprovalLogs &&
          <>
            {!!(approvalLogs?.length > number.ONE) && <div className='log-timeline'></div>}
            <OriginatorView />
          </>}
          {showApprovalLogs && <div className='see-morelogs-div' onClick={showLessLogs}>{label.SHOW_LESS_LOGS}</div>}  
      </section>
    </React.Fragment>
  )
}

export default Approvals
