import * as ACTION_TYPE from "./action-types";

export const setKanbanTasks = (data) => {
  return {
    type: ACTION_TYPE.SET_KANBAN_TASKS,
    payload: data,
  };
};

export const setKanbanGroupBy = (data) => {
  return {
    type: ACTION_TYPE.SET_GROUP_BY,
    payload: data,
  };
};
