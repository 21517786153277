import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertNotification } from '../../../actions/alertNotification';
import { setSelectedProjectSection } from '../../../actions/projectSidebar';
import { label, link, notifyIcon, number, quote } from '../../../config';
import { getMaxSortId } from '../../../helper/common';
import Bookmark from '../../../shared/components/Bookmark/Bookmark';
import store from '../../../store';
import { createDynamicTeamName } from '../../../utils/common';
import { updateBookmark } from '../../Bookmarks/bookmarks.service';
import HelpSupport from '../../HelpSupport/HelpSupport';
import { PROJECT_TYPE, sidepanelOptions } from '../projectSidebar.constants';

const ProjectSidebarSidepanel = () => {
  const [loading, setLoading] = useState(false);
  const [panelBarOptions, setPanelBarOptions] = useState(sidepanelOptions);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const bookmarks = useSelector((state) => state.bookmarks)
  const { projectDetails, selectedSection, selectedTab, type } = useSelector((state) => state.projectSidebar);


  useEffect(() => {
    if (type === PROJECT_TYPE.PERSONAL_PROJECT) {
      const newPanelBarOptions = sidepanelOptions.filter((data) => data.id !== "project-settings");
      setPanelBarOptions(newPanelBarOptions);
    }else if(type === PROJECT_TYPE.COMPANY_PROJECT){
      const newPanelBarOptions = sidepanelOptions.filter((data) => data.id !== "profile-preferences" && data.id !== "project-workflows");
      setPanelBarOptions(newPanelBarOptions);
    }
  }, [type])

  /**
 * open content sections by clicking quick action buttons
 * @param {element}
 * @returns {void}
 */
  const handleQuickActionButton = async (element) => {
    await dispatch(setSelectedProjectSection([...selectedSection, element.id]))
    var elmnt = await document.getElementById(element.id);
    setTimeout(() => { elmnt.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }, number.ONE_HUNDRED_FIFTY)
  }

  const isProjectBookmarked = bookmarks.projectBookmarks?.find((projectElement) => projectElement.Id === projectDetails.ProjectId);

  /**
  * handle add to bookmarks for Projects 
  * @param {void} 
  * @returns {void}
  */
  const handleProjectBookmark = async () => {
    setLoading(true)
    if (isProjectBookmarked || bookmarks.projectBookmarks.length < number.SEVEN) {
      let location = getMaxSortId(bookmarks.projectBookmarks, "SortId");
      const payload = { userId: user.id, entityType: 'Project', entityId: projectDetails.ProjectId, sortId: location + number.ONE }
      await dispatch(updateBookmark(payload));
    } else {
      store.dispatch(alertNotification(true, createDynamicTeamName(quote.PROJECT_BOOKMARK_ALERT , user.operationalTeamName) , notifyIcon.ERROR_ICON))
    }
    setLoading(false)
  }

  /**
  * bookmark remove floating button
  * @param {void} 
  * @returns {void}
  */
  const CustomItem = () => {
    return (
      <React.Fragment>
        <button className="project-floating-btn" onClick={handleProjectBookmark} >
          {label.REMOVE}
        </button>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tooltip anchorElement="target" position="left" parentTitle='true'>
        <div className='project-sidepanel-container h-100 d-flex flex-column align-items-center justify-content-between'>
          <div className='sidepanel-actions'>
            {projectDetails.QueueId && (user?.isAdmin || projectDetails.RoleId === number.FOUR || projectDetails.RoleId === number.FIVE) && 
            <Bookmark
              loading={loading}
              bookmarkBoolean={isProjectBookmarked}
              handleBookmarkFunction={handleProjectBookmark}
            />}
          </div>

          <div className={`sidepanel-quick-actions text-center d-flex flex-column align-items-center justify-content-center 
          ${(type === PROJECT_TYPE.COMPANY_PROJECT && (selectedTab !== number.ZERO)) || 
          (type === PROJECT_TYPE.PERSONAL_PROJECT && (selectedTab !== number.ONE)) ? "invisible" : ""}`}>
            {panelBarOptions.map((element) => {
              return <button
                title={element.label}
                className={`align-items-center justify-content-center cursor-pointer tooltip-text position-relative d-inline-block border-0 d-flex action-icons`}
                key={element.label}
                onClick={() => handleQuickActionButton(element)}
              >{element.icon}</button>
            })}
          </div>

          <div className='sidepanel-help'>
            <HelpSupport link={link.PROJECT_HELP_BOOKMARK} />
          </div>
        </div>
      </Tooltip>
    </React.Fragment>
  )
}

export default ProjectSidebarSidepanel