import { format, addDays, intervalToDuration, differenceInWeeks, getDay, isValid } from 'date-fns'
import { number } from '../../../config';
import { isDateValid } from '../../../shared/validators/validator';
import store from '../../../store';
import { accessBasedOnProjectMembers } from '../../../helper/common';

/**
 * It gives the respective day names for respective daysString
 * @param {string} daysString 
 * @author Muskan Thakur
 */
const getDaysFromNumbers = (daysString) => {  

  const daysMap = {
    0: 'Sunday', 
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };
  const daysArray = daysString.split(',').map(Number);
  const result = daysArray.map(day => daysMap[day] + '(s)');

  return result.join(' and ');
};

/**
 * It gives suffix for the respective date 
 * @param {Number} day 
 * @author Muskan Thakur
 */
const getDaySuffix = (day) => {
  if (day >= number.ELEVEN && day <= number.THIRTEEN) {
    return 'th';
  }
  switch (day % number.TEN) {
    case number.ONE: return 'st';
    case number.TWO: return 'nd';
    case number.THREE: return 'rd';
    default: return 'th';
  }
};

/**
 * It gives the respective dates for respective datesString
 * @param {string} datesString 
 * @author Muskan Thakur
 */
const formatDates = (datesString) => {
  
  const datesArray = datesString.split(','); 

  const formattedDates = datesArray.map(dateStr => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    return `${day}${suffix}`;
  });

  return formattedDates.join(' and ');
};

export const getRecurrTitle = (recur, duration, RemainingRecurrence, EndDate, FrequencyDayDate, FrequencyInterval, FrequencyIntervalType, WeeklyCustomRecurDay, MonthlyCustomRecurDates) => {
  let validateEndDate = EndDate && isDateValid(EndDate) && new Date(EndDate)
  
  switch (true) {
      //Gets Title for daily recurrence
      case ((recur === number.ONE) && (duration === number.ONE)): return (`This Task recurs daily`); 
      case ((recur === number.ONE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs daily until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.ONE) && (duration === number.THREE)): return (`This Task recurs daily for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "day" : "days"}`); 

      //Gets Title for weekly recurrence
      case ((recur === number.TWO) && (duration === number.ONE)): return (`This Task recurs weekly on ${format(FrequencyDayDate, 'EEEE')}`); 
      case ((recur === number.TWO) && (duration === number.TWO)): return validateEndDate && (`This Task recurs weekly until ${format(validateEndDate, 'do MMMM, yyyy')}`);
      case ((recur === number.TWO) && (duration === number.THREE)): return (`This Task recurs weekly on  ${format(FrequencyDayDate, 'EEEE')} for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "week" : "weeks"}`); 

      //Gets Title for monthly recurrence 
      case (recur === number.THREE && (duration === number.ONE)): return (`This Task recurs monthly on ${format(FrequencyDayDate, 'do')} of the month`); 
      case (recur === number.THREE && (duration === number.TWO)): return validateEndDate && (`This Task recurs monthly until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case (recur === number.THREE && (duration === number.THREE)): return (`This Task recurs monthly for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "month" : "months"}`); 

      //Gets Title for yearly recurrence
      case (recur === number.FOUR && (duration === number.ONE)): return (`This Task recurs yearly on ${format(FrequencyDayDate, 'do MMM')}`); 
      case (recur === number.FOUR && (duration === number.TWO)): return validateEndDate && (`This Task recurs yearly until ${format(validateEndDate, 'yyyy')}`); 
      case (recur === number.FOUR && (duration === number.THREE)): return (`This Task recurs yearly for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "year" : "years"}`); 

      //Gets Title for daily custom recurrence
      case ((recur === number.FIVE && FrequencyIntervalType === number.ONE) && (duration === number.ONE)): return (FrequencyInterval === number.ONE ? `This Task recurs daily` : `This Task recurs every ${FrequencyInterval} ${FrequencyInterval==number.ONE ? "day" : "days"}`); 
      case ((recur === number.FIVE && FrequencyIntervalType === number.ONE) && (duration === number.TWO)): return validateEndDate && (FrequencyInterval === number.ONE ? `This Task recurs daily until ${format(validateEndDate, 'do MMMM, yyyy')} ` : `This Task recurs every ${FrequencyInterval} ${FrequencyInterval==number.ONE ? "day" : "days"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.FIVE && FrequencyIntervalType === number.ONE) && (duration === number.THREE)):  return (FrequencyInterval === number.ONE ? `This Task recurs daily for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "day" : "days"}` :  `This Task recurs every ${FrequencyInterval} ${FrequencyInterval==number.ONE ? "day" : "days"} for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "day" : "days"}`);

       //Gets Title for weekly custom recurrence
       case ((recur === number.FIVE && FrequencyIntervalType === number.TWO) && (duration === number.ONE)): return (FrequencyInterval === number.ONE ? `The task recurs weekly on ${getDaysFromNumbers(WeeklyCustomRecurDay)}` : `This Task recurs every ${FrequencyInterval} weeks on ${getDaysFromNumbers(WeeklyCustomRecurDay)}`); 
       case ((recur === number.FIVE && FrequencyIntervalType === number.TWO) && (duration === number.TWO)): return validateEndDate && (FrequencyInterval === number.ONE ? `The task recurs weekly on ${getDaysFromNumbers(WeeklyCustomRecurDay)} until ${format(validateEndDate, 'do MMMM, yyyy')}` : `This Task recurs every ${FrequencyInterval} weeks on ${getDaysFromNumbers(WeeklyCustomRecurDay)} until ${format(validateEndDate, 'do MMMM, yyyy')}`);
       case ((recur === number.FIVE && FrequencyIntervalType === number.TWO) && (duration === number.THREE)): return (FrequencyInterval === number.ONE ? `This Task recurs weekly on ${getDaysFromNumbers(WeeklyCustomRecurDay)} for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "week" : "weeks"}` : `This Task recurs every ${FrequencyInterval} weeks on ${getDaysFromNumbers(WeeklyCustomRecurDay)} for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "week" : "weeks"}`); 

       //Gets Title for monthly custom recurrence 
       case (recur === number.FIVE && FrequencyIntervalType === number.THREE && (duration === number.ONE)): return (FrequencyInterval === number.ONE ? `The task recurs monthly on the  ${formatDates(MonthlyCustomRecurDates)}`  : `The task recurs every ${FrequencyInterval} months on ${formatDates(MonthlyCustomRecurDates)}`); 
       case (recur === number.FIVE && FrequencyIntervalType === number.THREE &&  (duration === number.TWO)): return validateEndDate && (FrequencyInterval === number.ONE ? `The task recurs monthly on ${formatDates(MonthlyCustomRecurDates)} until ${format(validateEndDate, 'do MMMM, yyyy')} ` : `The task recurs every ${FrequencyInterval} months on ${formatDates(MonthlyCustomRecurDates)} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
       case (recur === number.FIVE && FrequencyIntervalType === number.THREE &&  (duration === number.THREE)): return (FrequencyInterval === number.ONE ? `The task recurs monthly on ${formatDates(MonthlyCustomRecurDates)} for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "month" : "months"}` : `The task recurs every ${FrequencyInterval} months on ${formatDates(MonthlyCustomRecurDates)} for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "month" : "months"}`); 
 
       //Gets Title for yearly custom recurrence
       case (recur === number.FIVE && FrequencyIntervalType === number.FOUR && (duration === number.ONE)): return (FrequencyInterval === number.ONE ? `The Task recurs yearly on ${format(FrequencyDayDate, 'do MMM')}` : `The task recurs every ${FrequencyInterval} years on ${format(FrequencyDayDate, 'do MMM')}`); 
       case (recur === number.FIVE && FrequencyIntervalType === number.FOUR && (duration === number.TWO)): return validateEndDate && (FrequencyInterval === number.ONE ? `The Task recurs yearly until ${format(validateEndDate, 'yyyy')}` : `The task recurs every ${FrequencyInterval} years until ${format(validateEndDate, 'yyyy')}`); 
       case (recur === number.FIVE && FrequencyIntervalType === number.FOUR && (duration === number.THREE)): return (FrequencyInterval === number.ONE ? `The Task recurs yearly for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "year" : "years"}` : `The task recurs every ${FrequencyInterval} years for ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "year" : "years"}`); 
     
  }
}

export const getRecurrTitleCompletionBased = (recur, duration, skipCountValue, count, EndDate) => {
  let validateEndDate = EndDate && isDateValid(EndDate) && new Date(EndDate)
  switch (true) {
      //Gets Title for daily recurrence
      case ((recur === number.ONE) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"}`); 
      case ((recur === number.ONE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.ONE) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"} for next ${count} times`); 

      //Gets Title for weekly recurrence
      case ((recur === number.TWO) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"}`); 
      case ((recur === number.TWO) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.TWO) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"} for next ${count} times`); 


      //Gets Title for monthly recurrence 
      case ((recur === number.THREE) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"}`); 
      case ((recur === number.THREE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.THREE) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"} for next ${count} times`);  
  }
}

//counts the number of saturdays and sundays between end and start date 
 const countSatSun=(start, end)=>{
  if(end < start)return; 
      let count
      for(count = {sun:number.ZERO, sat:number.ZERO}; start <= end; start.setDate(start.getDate() + number.ONE)){
      if(start.getDay() == number.ZERO)count.sun++;
      else if(start.getDay() == 6)count.sat++;
      }
  return count;
  }

/**
 * updates endByCountValue according to the day chosen (saturday/sunday)
 */
export const endByCountValueSatSun = (endDate, skipDays, calenderValue,endByDate) => {
  let saturdaySundayCount=endByDate ? countSatSun(new Date(calenderValue),endDate) : countSatSun(new Date(calenderValue),new Date(addDays(calenderValue, endDate)))
  switch (true) {
    case (skipDays?.length == number.ZERO): return number.ZERO
    case (skipDays?.length == number.TWO): return saturdaySundayCount?.sat + saturdaySundayCount?.sun
    case (skipDays?.length == number.ONE && skipDays[number.ZERO] == number.SEVEN): return saturdaySundayCount?.sat
    case (skipDays?.length == number.ONE && skipDays[number.ZERO] == number.ONE): return  saturdaySundayCount?.sun
  }
}

/**
 * gets the number of dates between two dates excluding specified weekends 
 */
export const getDatesBetweenDatesDaily = (startDate, endDate, skipDays, calenderValue) => {
  let dates = []
  const theDate = new Date(startDate)
  let saturdaySundayCount=endByCountValueSatSun(endDate, skipDays, calenderValue,true)
  
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + number.ONE)
  }
  return dates.length - saturdaySundayCount
}

/**
 * returns dates based on the recurrence type and date range provided.
 */
export const getDatesBetweenDates = (recur,startDate, endDate, skipDays, calenderValue, frequencyIntervalType) => {
  let intervalToDurationCount
  if (startDate && endDate) {
    intervalToDurationCount = intervalToDuration({
      start: startDate,
      end: endDate
    })
  }
  switch (true) {
    case (recur === number.ONE): return getDatesBetweenDatesDaily(startDate, endDate, skipDays, calenderValue)
    case (recur === number.TWO): return differenceInWeeks(endDate,startDate)
    case (recur === number.THREE): return intervalToDurationCount?.months
    case (recur === number.FOUR): return intervalToDurationCount?.years
    case (recur === number.FIVE && frequencyIntervalType === number.ONE): return getDatesBetweenDatesDaily(startDate, endDate, skipDays, calenderValue)
    case (recur === number.FIVE && frequencyIntervalType === number.TWO): return differenceInWeeks(endDate,startDate)
    case (recur === number.FIVE && frequencyIntervalType === number.THREE): return intervalToDurationCount?.months
    case (recur === number.FIVE && frequencyIntervalType === number.FOUR): return intervalToDurationCount?.years
    default: return number.ZERO
  }
}

/**
 * checks if the user has access for creating recurrence or not 
 */
export const recurrenceAccess = (task) =>{
    const state = store.getState();
    const { id, myProjectId } = state.auth.user
    const { defaultDetails } =state.tasks
    let project = defaultDetails?.allProjectsList?.find((project) => project.ProjectId == task.baseProjectId)
    
    return accessBasedOnProjectMembers(project, id) 
    || ((task.ProjectId ==  myProjectId) && id == task.CreatedBy)
  }

/**
  * creates a payload for task recurrence details based on existing settings
  * exsiting reccurrence setting made by the user 
  * @author Muskan Thakur
  */
export const getExistingRecurrencePayload = (recurrenceDetails) => {
    let existingRecurrencePayload = {
      frequencyType: recurrenceDetails?.FrequencyType,
      frequencyDay: recurrenceDetails?.FrequencyDay,
      recurrenceMode: recurrenceDetails?.RecurrenceMode,
      recurrencePattern: recurrenceDetails?.RecurrencePattern,
      skipPastRecurrence: Boolean(recurrenceDetails?.SkipPastRecurrence),
      endCriteriaType: recurrenceDetails?.EndCriteriaType,
      endDate: recurrenceDetails?.EndDate ? format(new Date(recurrenceDetails?.EndDate), 'yyyy-MM-dd') : null,
      endRepeatCount: recurrenceDetails?.EndRepeatCount,
      skipDays: recurrenceDetails?.SkipDays,
      frequencyDayDate: format(new Date(recurrenceDetails?.FrequencyDayDate), 'yyyy-MM-dd'),
      reminder: recurrenceDetails?.Reminder,
      reminderType: recurrenceDetails?.ReminderType,
      remainingRecurrence: recurrenceDetails?.RemainingRecurrence,
      completionFrequencyType: recurrenceDetails?.CompletionFrequencyType ,
      completionFrequencyDays: recurrenceDetails?.CompletionFrequencyDays,
      taskAssignee: recurrenceDetails?.TaskAssignee,
      projectId: recurrenceDetails?.ProjectId,
      frequencyInterval: recurrenceDetails?.frequencyInterval, 
      frequencyIntervalType: recurrenceDetails?.frequencyIntervalType?.value,
      weeklyCustomRecurDay: recurrenceDetails?.weeklyCustomRecurDay?.map(obj => obj.value)?.join(','),
      monthlyCustomRecurDates: recurrenceDetails?.monthlyCustomRecurDates?.join(',')
    };
    return existingRecurrencePayload;
}

/**
  * creates a payload for task recurrence details based on current state variables and settings
  * current reccurrence setting made by the user 
  * @author Muskan Thakur
  */
export const createRecurrencePayload = (recur, recurrencePattern, calenderValue, recurrenceMode, skipDays, duration, endBydateValue, endByCountValue, skipWeekends, reminderValue, remainingRecurrence, taskAssignmentType, task, id, skipCountValue, assignedId, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, monthlyCustomRecurDates) => {
  return {
      taskId: task.taskId,
      frequencyType: recurrencePattern === number.ONE ? recur?.value : null,
      frequencyDay: getDay(new Date(getFrequencyDayDate(frequencyIntervalType, calenderValue, weeklyCustomRecurDay, monthlyCustomRecurDates, recur?.value))),
      recurrenceMode: recurrenceMode,
      recurrencePattern: recurrencePattern,
      skipPastRecurrence: skipDays.includes(true),
      endCriteriaType: duration?.value,
      endDate: endBydateValue ? format(endBydateValue, 'yyyy-MM-dd') : null,
      endRepeatCount: endByCountValue ? endByCountValue : null,
      skipDays: skipWeekends?.toString(),
      createdBy: id,  
      frequencyDayDate: getFrequencyDayDate(frequencyIntervalType, calenderValue, weeklyCustomRecurDay, monthlyCustomRecurDates, recur?.value),
      reminder: reminderValue?.length ? number.ONE : number.ZERO,
      reminderType: reminderValue.toString(),
      remainingRecurrence: remainingRecurrence,
      completionFrequencyType: recurrencePattern === number.TWO ? recur?.value : null,
      completionFrequencyDays: recurrencePattern === number.TWO ? skipCountValue : null,
      taskAssignee: taskAssignmentType?.value === number.TWO ? assignedId?.value : null,
      projectId: task?.CurrentProject,
      taskAssignmentType: taskAssignmentType?.value,
      workflowId: taskAssignmentType?.value === number.FIVE ? assignedId?.value : null,
      frequencyInterval: frequencyInterval, 
      frequencyIntervalType: frequencyIntervalType?.value,
      weeklyCustomRecurDay: weeklyCustomRecurDay?.map(obj => obj.value)?.join(','),
      monthlyCustomRecurDates: monthlyCustomRecurDates?.join(',')
  };
};

/**
 * Return the first selected weekday in the next recurrence cycle
 * @param {Date} currentDate 
 * @param {Array} selectedWeekdays 
 * @param {number} weeksToSkip 
 * @returns {Date}
 * @author Muskan Thakur
 */
export const getNextCustomRecurForWeek = (currentDate, selectedWeekdays, weeksToSkip) => {
  const dayOfWeek = currentDate.getDay();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
  selectedWeekdays.sort((a, b) => a - b);

  /**Finds the next weekday in the current week**/
  for (let i = number.ZERO; i < selectedWeekdays.length; i++) {
    if (selectedWeekdays[i] > dayOfWeek) {
      const nextDate = new Date(startOfWeek);
      nextDate.setDate(startOfWeek.getDate() + selectedWeekdays[i]);
      nextDate.setDate(nextDate.getDate() + number.ONE);
      return nextDate;
    }
  }

  const nextRecurrenceStart = new Date(startOfWeek);
  nextRecurrenceStart.setDate(startOfWeek.getDate() + weeksToSkip * number.SEVEN);

  const nextDate = new Date(nextRecurrenceStart);
  nextDate.setDate(nextRecurrenceStart.getDate() + selectedWeekdays[number.ZERO]);
  nextDate.setDate(nextDate.getDate() + number.ONE);
  return nextDate;
};

/**
 * Gets the next occurrence of a target weekday from the given date(user selcted date).
 * @param {Date} currentDate - The starting date.
 * @param {number} targetWeekday - The target weekday (0 for Sunday, 1 for Monday, etc.).
 * @returns {Date} - The next occurrence of the target weekday.
 * @author Muskan Thakur 
 */
export const getNextCustomRecurForWeekForCurrentDate = (currentDate, targetWeekday) => {
  const currentDayIndex = getDay(currentDate);
  let daysUntilTarget = targetWeekday - currentDayIndex;

   /**If the target day has already passed or it's the current day, add days to jump to the next week's target day*/
  if (daysUntilTarget < 0) {
      daysUntilTarget += 7;
  }

  /**Add the calculated days to get the next occurrence of the target weekday*/
  const nextTargetDate = new Date(currentDate);
  nextTargetDate.setDate(currentDate.getDate() + daysUntilTarget);
  return nextTargetDate;
};

/**
 * returns the next date in the recurring sequence, wrapping to the next month if needed.
 * @param {string[]} dates 
 * @param {string} currentDate 
 * @returns {string} 
 * @author Muskan Thakur
 */
export const getNextCustomRecurForMonth = (dates, currentDate, frequencyInterval) => {
  dates?.sort((a, b) => new Date(a) - new Date(b));
  let current = new Date(currentDate);
  current.setHours(0, 0, 0, 0);
  const currentDay = current.getDate();

  const currentIndex = dates?.findIndex(date => {
    let dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj.getDate() === currentDay;
  });

  // If it's the last date in the array, move to the next month's equivalent date with skip interval
  if (currentIndex === dates?.length - 1) {
    let nextMonthDate = new Date(dates[0]);

    // Increment the month by frequencyInterval and handle year rollover
    let nextMonth = current.getMonth() + frequencyInterval; // Skip the interval
    let nextYear = current.getFullYear();
    if (nextMonth > 11) {  
      nextYear += Math.floor(nextMonth / 12);  
      nextMonth = nextMonth % 12; 
    }

    nextMonthDate.setMonth(nextMonth);
    nextMonthDate.setFullYear(nextYear);
    return nextMonthDate.toISOString().split('T')[0];
  }

  // Otherwise, return the next date in the array within the same month
  const nextDate = new Date(dates[currentIndex + 1]);
  nextDate.setMonth(current.getMonth());
  nextDate.setFullYear(current.getFullYear());
  return nextDate.toISOString().split('T')[0];
};


/**
 * Gets dates previous sunday 
 * @param {Date} date 
 * @returns {Date}
 * @author Muskan Thakur
 */
export const getPreviousSunday = (date) => {
  const result = new Date(date);
  const day = result.getDay();
  const daysUntilSunday = day === 0 ? -7 : -day;
  result.setDate(result.getDate() + daysUntilSunday);
  result.setHours(0, 0, 0, 0);
  return result;
};

/**
 * Returns the nearest future date from a list of monthly custom recurrence dates.
 * @param {string[]} 
 * @returns {string}
 * @author Muskan Thakur 
 */
const getNearestFutureDate = (monthlyCustomRecurDates) => {
  const now = new Date();
  const currentDateStr = now.toISOString().split('T')[0];

  const futureDates = monthlyCustomRecurDates
    .filter(dateStr => {
      const date = new Date(dateStr);
      return date.toISOString().split('T')[0] >= currentDateStr;
    })
    .sort((a, b) => new Date(a) - new Date(b));

  return futureDates.length > 0 ? futureDates[0] : null;
};

/**
 * Gets frequency day date for recurrence 
 * @param {Object} frequencyIntervalType 
 * @param {Date} calenderValue
 * @param {Array} monthlyCustomRecurDates
 * @author Muskan Thakur
 */
export const getFrequencyDayDate = (frequencyIntervalType, calenderValue, weeklyCustomRecurDay, monthlyCustomRecurDates, recur) => {
  const selectedWeeklyDays = weeklyCustomRecurDay?.map(day => day.value);
  const nearestFutureDate = getNearestFutureDate(monthlyCustomRecurDates);
  const previousSunday = getPreviousSunday(new Date(calenderValue));
  if (frequencyIntervalType?.value === number.TWO && recur === number.FIVE) {
    const nextWeeklyDate = getNextCustomRecurForWeekForCurrentDate(previousSunday, selectedWeeklyDays[number.ZERO]);
    return isValid(nextWeeklyDate) ? format(nextWeeklyDate, 'yyyy-MM-dd') : null;
  }

  return (frequencyIntervalType?.value === number.THREE && recur === number.FIVE)
    ? nearestFutureDate 
    : format(calenderValue, 'yyyy-MM-dd');
};

/**
 * Generates a label for future assignee based on task assignment type and other details.
 * @param {number} taskAssignmentValue 
 * @param {number} taskAssignmentType 
 * @param {Array} workflows
 * @param {Object} project
 * @returns {string} 
 * @author Muskan Thakur
 */
export const generateAssigneeLabel = (taskAssignmentValue, taskAssignmentType, workflows, futureRecurrenceProject) => {
  const state = store.getState();
  
  const { user} = state.auth;
  const { defaultDetails } = state.tasks;
  const { task } = state.taskSidebar;

  const userName = defaultDetails?.assigneeList?.find(a => a.value === (taskAssignmentValue))
  const myProjectUserName = defaultDetails?.assigneeList?.find(a => a.value === (user.id))
  const workflowName = workflows?.find(workflow => workflow.value === taskAssignmentValue)

  const futureAssignment = taskAssignmentType === number.TWO ? userName : workflowName
 
  switch (true) {
    case taskAssignmentType === number.FIVE && user.myProjectId === task?.baseProjectId:
      return `Assignee: ${myProjectUserName?.label} in ${myProjectUserName?.label}'s board`;
    case taskAssignmentType === number.TWO:
      return `Assignee: ${futureAssignment?.label} in ${futureRecurrenceProject}`;
    case taskAssignmentType === number.FIVE:
      return `Assignee: Default assignee of ${futureAssignment?.label} workflow in ${futureRecurrenceProject}`;
    default:
      return '';
  }
};