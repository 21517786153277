import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ORGANIZATION_FEATURES_TITLES } from "../Admin/ManageOrganization/OrganizationFeatures/organizationFeatures.constants";
import { getOrganization } from "../Admin/admin.service";

/**
 * Checks if Classic Buttton should be visible in Collab Central or not
 * @returns {boolean}
 * @author Ankit Negi
 */
export const useShowClassicButton = () => {
  const { companyId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const ORGANIZATION_ID = companyId ? companyId : user.companyId;

  const [showClassicButton, setShowClassicButton] = useState(false);

  const fetchCollabCentralFeature = async () => {
    const response = await getOrganization(ORGANIZATION_ID);
    const { featureList } = response ?? {};
    const collabCentralFeature = featureList.find((feature) => feature.Name === ORGANIZATION_FEATURES_TITLES.COLLAB_CENTRAL);

    const isCollabCentralFeatureActive = collabCentralFeature.IsActive === 1;
    setShowClassicButton(isCollabCentralFeatureActive);
  };

  useEffect(() => {
    fetchCollabCentralFeature();
  }, []);

  return showClassicButton;
};
