import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import ProjectSettings from './ProjectSettings';
import { checkRequiredFields, getUpdateProjectPayload, handleProjectDetailsUpdate } from '../../projectSidebar.common';
import { updateProjectNew, saveProjectStages, getProjectDetails } from '../../../../shared/services/projects.service';
import { closeProjectSidebar, setAssignees, setSelectedProjectSection, setStageList, updateProjectDetails } from '../../../../actions/projectSidebar';
import { button, kendo, label, number, quote } from '../../../../config';
import { PROJECT_TYPE, sidepanelOptions } from '../../projectSidebar.constants';
import { appendString } from '../../../../utils/common';
import WorkflowPanel from './WorkflowPanel';
import ProjectStagesGrid from './ProjectStagesGrid/ProjectStagesGrid';
import { removeExtraSpaces } from '../../../../helper/common';
import PreferencesSettings from '../../../../shared/layouts/Header/UserProfile/PreferencesSettings';

const ProjectSidebarSections = (props) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isProjectCode, setIsProjectCode] = useState(true);
  const [panelBarOptions, setPanelBarOptions] = useState([]);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth)
  const team = useSelector((state) => state.team);
  const { selectedSection, projectDetails, updatedFields, isDisabled, type } = useSelector((state) => state.projectSidebar);
  const { operationalTeamName } = useSelector((state) => state.auth.user);
  const { ProjectId, ProjectName } = projectDetails;

  useEffect(() => {
    if (type === PROJECT_TYPE.PERSONAL_PROJECT) {
      const newPanelBarOptions = props?.workflows ? sidepanelOptions?.filter((panel) => panel?.id === "project-workflows") :sidepanelOptions.filter((panel) => panel?.id !== "project-settings" && panel?.id !=="project-workflows") ;
      setPanelBarOptions(newPanelBarOptions);
    }else if(type === PROJECT_TYPE.COMPANY_PROJECT){
      const newPanelBarOptions = props?.workflows ? sidepanelOptions?.filter((panel) => panel?.id === "project-workflows") :sidepanelOptions?.filter((panel) => (panel?.id !== "profile-preferences" && panel?.id !== "project-workflows"))
      setPanelBarOptions(newPanelBarOptions);
    }
  }, [type])

  /**
   *for personal project the stage panel opens in configure and workflow panel opens in workflow tab and for company projects the settings panel opens and workflow panel opens in workflow tab
   * @author Sartak Arora
   */
  useEffect(() => {
    (async () => {
      if(type === PROJECT_TYPE.COMPANY_PROJECT)
       dispatch(setSelectedProjectSection([props.workflows ?'project-workflows' : "project-settings" , "project-stages" ]));
      else if (type === PROJECT_TYPE.PERSONAL_PROJECT)
      dispatch(setSelectedProjectSection([props.workflows ?'project-workflows' : "project-stages"]));
    })()
  }, [props.workflows,type]);

  useEffect(() => {
    setName(ProjectName ? ProjectName : "");
  }, [projectDetails])



  useEffect(() => {
    dispatch(setAssignees(assigneeOptions()))
  }, [team]);

  const isProjectCodeEmpty = (projectCode) => {
    setIsProjectCode(projectCode.length >= number.FOUR)
  }

  /**
   * filters assignee options
   * @param {void}
   * @return {Array}
   * @author Himanshu Negi
   */
  const assigneeOptions = () => {
    let options = [];
    team?.projectMembers?.map(user => {
      if (user.RoleId === number.FOUR || user.RoleId === number.FIVE) { options.push({ value: user.UserId, label: user.Name, accountStatus: user.AccountStatus }) }
      return null;
    })
    return options;
  }

  const handleSelect = (event) => {
    if (event.expandedItems) {
      dispatch(setSelectedProjectSection(event.expandedItems));
    }
  }

  /**
 * used to set value of Project Name
 * @param {event}
 * @author Himanshu Negi
 */
  const handleProjectName = (event) => {
    let projectName = event.target.value;
    if (checkRequiredFields("name", projectName, setName, setNameError)) {
      setName(projectName);
      setNameError(false);
    }
  };


  const handleProjectNameBlur = () => {
    handleProjectDetailsUpdate('name', removeExtraSpaces(name))
  }

  /**
   * updates Project and Project Details
   * @param {*} payload
   */

  const updateProjectAndDetails = (payload) => {
    dispatch(updateProjectNew({ userId: user?.id, companyId: user?.companyId, projectId: ProjectId, ...payload }))
    dispatch(updateProjectDetails(updatedFields));
    dispatch(closeProjectSidebar())
  }

  /**
  * updates projects details and stages
  * @param {void}
  * @return {void}
  * @author Himanshu Negi
  */
  const handleProjectUpdate = () => {
    const payload = getUpdateProjectPayload(updatedFields);
    if (Object.keys(updatedFields).length !== number.ZERO)
       updateProjectAndDetails(payload)
  }

  const handleSidebarClose = () => {
    dispatch(closeProjectSidebar())
  }

  /**
    * returns components of accordian
    * @param {String} Id
    * @return {Component}
    */
  const getComponent = (id) => {
    switch (id) {
      case 'project-settings':
        return <ProjectSettings isProjectCodeEmpty={isProjectCodeEmpty} />
      // case 'project-defaults': might need this later
      //   return <DefaultSettings />
      case 'profile-preferences':
        return <PreferencesSettings/>
      case 'project-stages':
        return <ProjectStagesGrid projectId={projectDetails?.ProjectId}/>
      case 'project-workflows':
        return <WorkflowPanel />
      default :
        return <></>
    }
  }
  return (
    <React.Fragment>
      <div className='existing-project-details-container d-flex flex-column'>
        {type === PROJECT_TYPE.COMPANY_PROJECT && !props.workflows && <header className='existing-project-header d-flex align-items-center px-2'>
        <div className='projectname-input-container flex-grow-1 d-flex flex-column'>
            <Input type="text" id="name" placeholder={appendString(operationalTeamName, label.NAME)} required autoComplete="off" maxLength={kendo.INPUT_MAX_LENGTH} value={name} onChange={handleProjectName} onBlur={handleProjectNameBlur} disabled={isDisabled} />
            {<small className={`text-danger ${!nameError && 'invisible'}`}>{quote.REQUIRED_FIELD}</small>}
          </div>
        </header>}
        <div className='project-accordian-container flex-grow-1 overflow-y-scroll'>
          <PanelBar
            isControlled={true}
            expanded={selectedSection}
            onSelect={handleSelect}
          >
            {panelBarOptions.map((option, idx) => (
              <PanelBarItem key={idx.toString()} id={option.id} title={option.label}>
                {getComponent(option.id)}
              </PanelBarItem>
            ))}
          </PanelBar>
        </div>
        <footer className='existing-project-footer row'>
          {String(ProjectId) !== String(user?.myProjectId) &&
          <div className="col-md-12 footer-buttons d-flex align-items-center justify-content-end">
            <button type="button" className="btn btn-secondary btn-width mr-2" onClick={handleSidebarClose}>{button.CANCEL}</button>
            <button
            id="project-sidebar-sections-save-btn"
            type="submit"
            className="btn btn-primary btn-width"
            onClick={handleProjectUpdate}
            disabled={(!name || !isProjectCode)}> {button.SAVE} </button>
          </div>
          }
        </footer>
      </div>
    </React.Fragment>
  )
}

export default ProjectSidebarSections
