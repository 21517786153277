import * as ACTION_TYPES from "../actions/action-types";
import { number } from "../config";
const initialState = {
  unclearedNotifications: [],
  allNotifications: [],
  showNotification: false,
  notificationCount: number.ZERO,
  navigatedNotification: null,
  isUserInNotificationPage: false,
  approvalCount: number.ZERO,
  reminderCount: number.ZERO,
  commentCount: number.ZERO
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const notifications = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.GET_UNCLEARED_NOTIFICATIONS:
      return {
        ...state,
        unclearedNotifications: payload
      }

    case ACTION_TYPES.SHOW_NOTIFICATIONS:
      return {
        ...state,
        showNotification: payload
      }

    case ACTION_TYPES.EMPTY_UNCLEARED_NOTIFICATIONS:
      return {
        ...state,
        unclearedNotifications: [],
      }
    case ACTION_TYPES.SET_NOTIFICATION_HEADER_COUNTS:
      return {
        ...state,
        notificationCount: payload.totalCount,
        reminderCount: payload.reminderCount,
        commentCount: payload.commentCount,
        approvalCount: payload.approvalCount,
      }
    case ACTION_TYPES.SET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationCount: payload
      }
    case ACTION_TYPES.SET_NAVIGATED_NOTIFICATION:
      return {
        ...state,
        navigatedNotification: payload
      }

    case ACTION_TYPES.IS_USER_IN_NOTIFICATION_PAGE:
      return {
        ...state,
        isUserInNotificationPage: payload
      }
    default:
      return state;
  }
}

export default notifications;