import { useState, createContext, useContext } from "react";

export const UserProfileContext = createContext({
  timeZoneList: [],
  setTimeZoneList: () => {},
  manager: null,
  setManager: () => {},
  selectedImage: null,
  setSelectedImage: () => {},
  timeZone: null,
  setTimeZone: () => {},
  displayName: null,
  setDisplayName: () => {},
  userEmail: null,
  setUserEmail: () => {},
  jobTitle: null,
  setJobTitle: () => {},
  taskCode: null,
  setTaskCode: () => {},
  reminderTime: null,
  setReminderTime: () => {},
  boardName: null,
  setBoardName: () => {},
  managerList: [],
  setManagerList: () => {},
  initialManagerList: [],
  setInitialManagerList: () => {},
  googleSSOValues: [],
  setGoogleSSOValues: () => {},
  userIdValues: [],
  setUserIdValues: () => {},
  selectedImageFolder: "",
  setSelectedImageFolder: () => {},
  profileData: {},
  setProfileData: () => {},
  initialTimeZone: null,
  setInitialTimeZone: () => {}
});

/**
 * Wrapper component for user profile context provider
 * @autor Bhavana
 */
export const UserProfileContextProvider = ({ children }) => {
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [manager, setManager] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [initialTimeZone, setInitialTimeZone] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [taskCode, setTaskCode] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const [boardName, setBoardName] = useState(null);
  const [managerList, setManagerList] = useState([]);
  const [initialManagerList, setInitialManagerList] = useState([]);
  const [googleSSOValues, setGoogleSSOValues] = useState([]);
  const [userIdValues, setUserIdValues] = useState([]);
  const [selectedImageFolder, setSelectedImageFolder] = useState("");
  const [profileData, setProfileData] = useState({});

  const value = {
    timeZoneList,
    setTimeZoneList,
    manager,
    setManager,
    selectedImage,
    setSelectedImage,
    timeZone,
    setTimeZone,
    displayName,
    setDisplayName,
    userEmail,
    setUserEmail,
    jobTitle,
    setJobTitle,
    taskCode,
    setTaskCode,
    reminderTime,
    setReminderTime,
    boardName,
    setBoardName,
    managerList,
    setManagerList,
    initialManagerList,
    setInitialManagerList,
    googleSSOValues,
    setGoogleSSOValues,
    userIdValues,
    setUserIdValues,
    selectedImageFolder,
    setSelectedImageFolder,
    profileData,
    setProfileData,
    initialTimeZone,
    setInitialTimeZone
  };

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

// Custom hook to use the UserProfileContext
export const useProfileContext = () => {
  return useContext(UserProfileContext);
};
