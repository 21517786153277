import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { icon, label, number } from '../../config';
import { useSelector } from 'react-redux';
import { arrayOfObjects, formatDate, generateTimeArray, generateUniqueId, searchUsers } from '../editor.helper';
import { Popup } from '@progress/kendo-react-popup';
import { DayPicker } from 'react-day-picker';
import { Input } from '@progress/kendo-react-inputs';
import { useEditorContext } from '../editor.Context';


/**
* Inserts Date Chip  for Editor
* PC SmartChip
* @author Shivam Mishra
*/
const DateChip = ({ updateNonEditable }) => {
    const { user } = useSelector((state) => state.auth);
    const [selected, setSelected] = useState(new Date());

    /**
    * Function to handle insert date chip.
    * @param {Null} .
    * @author Shivam Mishra
    */
    const handleInsertNode = useCallback(async () => {
        const id = await generateUniqueId(user.id, "date", null);
        updateNonEditable(formatDate(selected), id , "date-chip");
    }, [user.id, selected]);

    /**
    * Handles the default date selection event.
    * @author {Shivam}
    */
    const handleDateSelect = async () => {
        const id = await generateUniqueId(user.id, "date", null);
        updateNonEditable(label.SELECT_DATE , id , "date-chip");
    };

    return (
        <div className="zoom">
            <button onClick={handleDateSelect} className='btn ml-2 mt-2 p-2 '>
                {label.SELECT_DATE}
            </button>
            <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                id="editor-smart-date-picker"
            />
            <div className='pl-3 pr-3 mb-3 d-flex justify-content-between position-relative' id="editor-smart-time-picker">
                <button className='btn btn-primary float-right d-block' onClick={() => { handleInsertNode() }}>{label.SET}</button>
            </div>
        </div>
    );
};

/**
* Inserts dd Chip  for Editor
* PC SmartChip
* @author Shivam Mishra
*/
const DropDownChip = ({ updateNonEditable }) => {
    const { user } = useSelector((state) => state.auth);
    const firstElements = arrayOfObjects?.map(obj => ({ name: obj.value[0], id: obj.id }));
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [showDetailPopup, setShowDetailPopup] = useState(false);
    const ddRef = useRef(null);

    const anchorAlign = {
        horizontal: 'right',
        vertical: 'center',
    };

    const popupAlign = {
        horizontal: 'left',
        vertical: 'top',
    };

    useEffect(() => {
        return () => {
            ddRef.current = null;
        };
    }, []);

    /**
    * Function to update ref and open respective dd.
    * @param {Integer}id  of dd .
    * @author Shivam Mishra
    */
    const handleMouseEnter = useCallback(async (id) => {
        setShowDetailPopup(true);
        setHoveredIndex(id);
        ddRef.current = document.getElementById(`dd-options-${id}`);
    }, []);

    /**
    * Function to handle insert dd chip.
    * @param {String}Name Name of dd .
    * @author Shivam Mishra
    */
    const updateNode = useCallback(async (event, name) => {
        event.preventDefault();
        const id = await generateUniqueId(user.id, "dd", hoveredIndex);
        await updateNonEditable(name, id);
    }, [hoveredIndex]);

    return (
        <div className='dt-editor-users overflow-auto w-100'>
            {firstElements?.map(({ name, id }, index) => (
                <div
                    className='dt-editor-users-item cursor-pointer'
                    key={index}
                    onMouseEnter={() => handleMouseEnter(id)}
                    id={`dd-options-${id}`}
                    ref={hoveredIndex === index ? ddRef : null}
                    onClick={(event) => updateNode(event, name)}
                >
                    {name}
                </div>
            ))}
            <Popup show={showDetailPopup} anchor={ddRef.current} anchorAlign={anchorAlign} popupAlign={popupAlign}>
                {arrayOfObjects.find(obj => obj.id === hoveredIndex)?.value?.map((name, index) => (
                    <div className='dt-editor-users cursor-pointer word-break-all overflow-auto w-100'>
                        <div className='dt-editor-users-item cursor-pointer' id={`editor-smart-dd${index}-picker`} key={index} onClick={(event) => updateNode(event, name)}>{name}</div>
                    </div>
                ))}
            </Popup>
        </div>
    );
};

const TimeChip = ({ updateNonEditable }) => {
    const { user } = useSelector((state) => state.auth);
    const [selectedTime, setSelectedTime] = useState('00:00');
    const timeArray = useMemo(() => generateTimeArray(), []);

    /**
    * Function to update time for timePicker .
    * @param {event}.
    * @author Shivam Mishra
    */
    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    /**
    * Function to handle insert date chip.
    * @param {Null} .
    * @author Shivam Mishra
    */
    const handleInsertNode = useCallback(async () => {
        const id = await generateUniqueId(user.id, "time", null);
        updateNonEditable(selectedTime, id , "time-chip");
    }, [user.id, selectedTime]);

    /**
    * Handles the default time selection event.
    * @author {Shivam}
    */
    const handleTimeSelect = async() => {
        const id = await generateUniqueId(user.id, "time", null);
        updateNonEditable(label.SELECT_TIME , id, "time-chip");
    };
    

    return (
        <div className='p-3 overflow-hidden'>
            <button onClick={handleTimeSelect} className='mr-2 p-2'>
                {label.SELECT_TIME}
            </button>
            <h6>{label.TIME}</h6>
            <div>
                <div className='mb-3 position-relative' id="editor-smart-time-picker">
                    <div className='time-options w-100 overflow-y-auto'>
                        {timeArray.map((time) => (
                            <div key={time} className={`time-option cursor-pointer ${time === selectedTime ? 'blue active-time-list' : ''}`} onClick={() => { handleTimeChange(time) }}>
                                {time}
                            </div>
                        ))}
                    </div>
                    <button className='btn btn-primary float-left d-block mt-3' onClick={() => { handleInsertNode() }}>{label.SET}</button>
                </div>
            </div>
        </div>
    );
};

const componentsData = {
    DateChip: { component: DateChip, text: 'Date', icon: icon.PLAN_DO_TOMORROW },
    TimeChip: { component: TimeChip, text: 'Time', icon: icon.ALARM }
    // DropDownChip: { component: DropDownChip, text: 'Dropdown' }
};

/**
* Inserts Chip  for Editor
* PC KendoEditor
* @author Shivam Mishra
*/
const SmartChip = ({ updateNonEditable, assigneeList, setAssigneeList }) => {
    const { defaultDetails } = useSelector((state) => state.tasks);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const { user } = useSelector((state) => state.auth);

    const editorState = useEditorContext();
    const { selectedIndex, setSelectedIndex, containerRef, acknowledgeChip } = editorState;

    const componentProps = {
        updateNonEditable: updateNonEditable,
    };

    /**
     * useeffect to set default selected option.
     * @author Shivam Mishra
     */
    useEffect(() => {
        setSelectedIndex(number.ZERO);
    }, [assigneeList]);

    /**
     * Function to handle option select.
     * @param {String} componentName Name of the selected component.
     * @author Shivam Mishra
     */
    const handleOptionSelect = useCallback((componentName) => {
        setSelectedComponent(componentName);
    }, []);

    /**
     * Function to update node.
     * @param {Object} assignee Object containing label and value.
     *@author Shivam Mishra
     */
    const updateNode = useCallback(async (assignee) => {
        const { label, value } = assignee;
        const id = await generateUniqueId(user.id, "user", value);
        updateNonEditable(label, id , "user-chip");
    }, []);

    /**
    * Function to update assignee list .
    * @param {e} event.
    * @author Shivam Mishra
    */
    const updateUserList = (e) => {
        setAssigneeList(searchUsers(e.target.value, defaultDetails, assigneeList))
    }

    /**
     * Function to update node.
     * @param {Object} assignee Object containing label and value.
     *@author Shivam Mishra
    */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && assigneeList.length) {
            e.preventDefault();
            updateNode(assigneeList[selectedIndex])
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex(prevIndex => Math.max(number.ZERO, prevIndex - number.ONE));
        } else if (e.key === 'ArrowDown') {
            setSelectedIndex(prevIndex => Math.min(assigneeList.length - number.ONE, prevIndex + number.ONE));
        }
    }

    return (
        <>
            {!selectedComponent ? (
                <div className='dt-editor-menu'>
                    <div className='custom-editor-section' id="editor-people-chips">
                        <div className='d-flex justify-content-between p-3'>
                            <div className='font-weight-bold'>
                                {label.USER}
                            </div>
                            <div className='small'>{label.ESC_PRESS}</div>
                        </div>
                        <div className='pl-3 pr-3'><Input placeholder={label.SEARCH_USER} onChange={updateUserList} autoFocus={true} onKeyDown={handleKeyDown} /></div>
                        <div className='dt-editor-users overflow-auto overflow-x-hidden w-100' ref={containerRef} >
                            {assigneeList?.map((assignee, index) => (
                                <div className={`dt-editor-users-list cursor-pointer d-flex ${index === selectedIndex ? 'user-selected' : ''}`} key={index} onClick={() => { updateNode(assignee) }}>
                                    <div className='mr-2'>{assignee?.PhotoLink ? <img src={assignee?.PhotoLink} className='userlist-dp rounded-circle' /> : <div className='userlist-icon'>{icon.ACCOUNT_CIRCLE}</div>}</div>
                                    <div>
                                        <div className="assignee-info d-block">{assignee?.label}</div>
                                        <div className='small info-text'>{assignee?.Email}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-editor-section' id="editor-smart-chips">
                        <div className='dt-editor-action'>
                            {label.SMART_CHIPS}
                        </div>
                        <div className='overflow-auto w-100 d-flex p-3'>
                            {Object.keys(componentsData)?.map((key) => (
                                <div className='cursor-pointer smart-chip-icon' id={`editor-smart-chips-${key}`} key={key} onClick={() => handleOptionSelect(key)}>
                                    {componentsData[key].icon}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {React.createElement(componentsData[selectedComponent].component, componentProps)}
                </div >
            )}
        </>
    );
};

export default React.memo(SmartChip);