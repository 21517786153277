import { groupBy } from "@progress/kendo-data-query";
import { formatDate } from "../../helper/common";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import './comment.scss';
import React from "react";
import { number } from "../../config";
import { useSelector } from "react-redux";
import { removeAllImgTags, removeHtmlTags } from "../../config/regex";

/**
 * Used to filter comments tasks for the created on date
 * @param {*} tasks
 * @author Bhavana
 * @returns {Array} filtered task that have Comment Date
 */
export const formatCommentTasks = (tasks) => {
  const filteredTasks = []
  tasks?.map((task) => {
    task.Date = task.CreatedOn ? formatDate(task.CreatedOn) : ""
    filteredTasks.push(task);
  })
  return filteredTasks;
}

/**
 * Initial group for comments sorting.
 * @author Bhavana
 */
export const initialCommentGroup = [{ field: "Date" }];

/**
 * Groups the data based on the provided group field and updates the groupIds state.
 * @author Bhavana
 */
export const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({ data: newDataState, group: group });
  return newDataState;
};

/**
 * Custom row render function for rendering rows with specific styles and behaviors.
 * @author Bhavana
 */
export const CustomRowRender = (props) => {
  const { task } = useSelector((state) => state.taskSidebar)
  const { dataItem, rowType } = props.originalProps;
  const rowProjectTaskId = dataItem.Id;
  const selectedRow = rowProjectTaskId && task?.taskId && rowProjectTaskId == task?.taskId

  if (rowType !== "groupHeader") {
    const isRead = dataItem.IsRead;
    const trClassName = isRead === number.ONE ? 'white-bg' : 'pastel-bg';
    const trProps = {
      onBlur: () => props.exitEdit(),
      onMouseEnter: () => props.setHover({ id: dataItem.Id }),
      onMouseLeave: () => props.setHover({ id: number.ZERO }),
      className: selectedRow ? `active-bg ${trClassName}` : trClassName
    };
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
  }
  else {
    const trProps = {
      ...props?.tr?.props,
    };
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
  }
};

/**
 * Creates a modified payload object by changing `isTagged` and `isRead` properties based on the provided conditions.
 *@author Bhavana
 */
export const createModifiedPayload = (payload, allTagComments, allUnreadComments) => {
  return {
    ...payload,
    isTagged: allTagComments ? number.ONE : number.ZERO,
    isRead: allUnreadComments ? number.ONE : number.ZERO
  };
};

/**
 * Removes all <img> tags from the given text.
 *@author Bhavana
 */
export const removeImgTags = (text) => text.replace(removeAllImgTags, '');
/**
 * remove all HTML tags from the given HTML string.
 *@author Bhavana
 */
export const stripHtmlTags = (html) => html.replace(removeHtmlTags, '');
