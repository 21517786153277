import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useDispatch, useSelector } from "react-redux";
import { dateConvert } from "../../../../src/utils/common";
import { setReminderId, updateCommonGridReminders } from "../../../actions/reminders";
import { label, number } from "../../../config/index";
import { formatTime } from '../../../helper/common';
import { planMyDayReminderActions } from '../../TaskSidebar/PlanMyDayActions/planMyDay.helper';
import { handleTaskKeyUpdate } from '../tasks.service';
import { addReminder } from './reminder.service';
import './reminder.scss';
/**
* Default date Picker
* @returns datePicker
* @author Shivam Mishra
*/

export const ReminderForm = React.memo((fieldRenderProps = fieldRenderProps) => {
    const [show, setShow] = React.useState(false);
    const { handleAddRem, TaskId, reminderId, anchor, Name, reminderDate, reminderTime, disable, setEditAddRem, fromGrid, setIsSetClicked, setUpdatedValue } = fieldRenderProps
    const [reminderMessage, setReminderMessage] = React.useState(Name);
    const { task } = useSelector((state) => state.taskSidebar);
    const { id, UserReminderTime } = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [minDate] = useState(new Date);
    const formattedTime = formatTime(UserReminderTime, 'HH:mm');
    const [selectedDate, setSelectedDate] = useState(reminderDate ? new Date(reminderDate) : new Date);
    const [selectedTime, setselectedTime] = useState(reminderTime ? reminderTime : formattedTime);
    const {commonGridReminders} = useSelector((state)=> state.reminders)

    /**
     * returns the updated reminder
     * @param {*} payload 
     * @returns {Object} updatedReminder
     * @author Sarthak Arora
     */
    const getUpdatedReminders = (payload) => {
        const {reminderId, reminderMessage, reminderTime , reminderDate} = payload;
        const updatedReminder = commonGridReminders.find((rem) => rem.Id === reminderId);
        if(updatedReminder){
        updatedReminder.ReminderMessage = reminderMessage
        updatedReminder.ReminderTime = reminderTime
        updatedReminder.ReminderDate = reminderDate
        }
        const updatedCommonGridReminders = commonGridReminders.map(reminder => reminder.Id === reminderId ?updatedReminder : reminder)

        return updatedCommonGridReminders;
    }

    const handleSubmit = useCallback(async (e) => {
        const payload = {
            userId: id,
            reminderMessage: reminderMessage,
            reminderTime: selectedTime,
            reminderDate: dateConvert(selectedDate),
            entityId: TaskId,
            reminderId: reminderId ? reminderId : null
        }

        handleAddRem && handleAddRem(false)
        if (setEditAddRem) setEditAddRem(false);
        dispatch(setReminderId(null, null))
        if (!reminderId) {
            handleTaskKeyUpdate(false, "reminderCount", (task.reminderCount + number.ONE))
        }
        const res = await dispatch(addReminder(payload, false, fromGrid ? true : false))
        if (res) dispatch(updateCommonGridReminders(getUpdatedReminders(payload)))
        planMyDayReminderActions();
        setIsSetClicked && setIsSetClicked(true);
        setUpdatedValue && setUpdatedValue({
            reminderMessage: reminderMessage,
            reminderTime: selectedTime,
            reminderDate: dateConvert(selectedDate),
        })
    }, [reminderMessage, selectedTime, selectedDate, TaskId, reminderId])

    /**
     *  sets the reminderMessage
     *  @author: Sarthak Arora
     */
    const inputEvent = (event) => {
        setReminderMessage(event.target.value)
    }

    /**
     *  array of all the disabled dates (all previous dates)
     *  @author: Sarthak Arora
     */

    const disabledDays = [
        { from: new Date(1990, 4, 18), to: new Date }
    ];

    return (
        <PopupPropsContext.Provider
            value={(props) => ({
                ...props,
                appendTo: anchor.current,

            })}
        >
            <div className='form-group mt-2'>
                <label>{label.TITLE}</label>
                <input
                    id='form-components-reminder-title'
                    className='w-100 form-control'
                    type="text"
                    onChange={inputEvent}
                    placeholder={label.TITLE}
                    defaultValue={Name}
                />
            </div>

            <DayPicker className='m-0 p-0'
                mode='single'
                selected={selectedDate}
                onSelect={setSelectedDate}
                disabled={disable || disabledDays}

            />

            <div className='d-flex '>
                <Input
                    id='form-components-reminder-time-input'
                    className="mr-2 mt-3"
                    type='time'
                    defaultValue={selectedTime}
                    onChange={(e) => { setselectedTime(e.target.value) }}
                />

                <Button id="form-components-reminder-set-btn" className='btn btn-primary mt-3' onClick={handleSubmit}>{label.SET}</Button>
            </div>
        </PopupPropsContext.Provider>
    );
});