import React, { useState, useContext, useRef } from 'react';
import { number } from '../config';

// Create the context
const editorContext = React.createContext();

/**
 * Provider component for managing editor state.
 * @param {object} children - The child components.
 * @returns {JSX.Element} Provider component with editor context.
 * @author Shivam Mishra
 */
export const EditorStateProvider = ({ children }) => {
  // hovered popup state 
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState('');
  const [selectedLink, setSelectedLink] = useState(null);
  const [taskInfo, setTaskInfo] = useState(null);
  const cancelTokenRef = useRef(); 
  // smart chip popup state
  const [showInputPopup, setInputPopup] = useState(false);
  const [smartChipTarget, setChipTarget] = useState(null);
  const prevKeyRef = useRef(null);
  const [editChip, setEditChip] = useState();
  const [showEditChip, setShowEditChip] = useState();
  const [smartChipId, setSmartChipId] = useState();
  const [tempChipID , setTempChipId] = useState() ; 
  // task search popup 
  const [showTaskSearchPopup, setTaskSearchPopup] = useState(false);
  const overallSearchRef = React.useRef();
  // image popup state
  const [img, setImg] = useState();
  const [openImage, setOpenImage] = useState(false);
  // editor mode 
  const modeRef = React.useRef(); 
  // navigator 
  const [selectedIndex, setSelectedIndex] = useState(number.ZERO);
  const containerRef = useRef(null);
  // iframe state 
  const [editIframe , setEditFrame] = useState(false) ; 
  const [editIframeId , setEditFrameId] = useState(null) ; 
  const [visibleDialog, setVisibleDialog] = React.useState(null);

  React.useEffect(() => {
      if (containerRef.current) {
          const selectedElement = containerRef.current.children[selectedIndex];
          if (selectedElement) {
              selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
      }
  }, [selectedIndex]);

  return (
    <editorContext.Provider
      value={{
        visible,
        setVisible,
        url,
        setUrl,
        selectedLink,
        setSelectedLink,
        taskInfo,
        setTaskInfo,
        cancelTokenRef,
        showInputPopup,
        setInputPopup,
        smartChipTarget,
        setChipTarget,
        prevKeyRef,
        showTaskSearchPopup,
        setTaskSearchPopup,
        editChip,
        setEditChip,
        showEditChip,
        setShowEditChip,
        smartChipId,
        setSmartChipId,
        img,
        setImg,
        openImage,
        setOpenImage , 
        overallSearchRef , 
        modeRef ,
        selectedIndex, 
        setSelectedIndex,
        containerRef , 
        tempChipID , setTempChipId , 
        editIframeId , setEditFrameId , 
        editIframe , setEditFrame,
        visibleDialog, setVisibleDialog
      }}
    >
      {children}
    </editorContext.Provider>
  );
};

// Custom hook to use the context values
export function useEditorContext() {
    const {
        visible,
        setVisible,
        url,
        setUrl,
        selectedLink,
        setSelectedLink,
        taskInfo,
        setTaskInfo,
        cancelTokenRef,
        showInputPopup,
        setInputPopup,
        smartChipTarget,
        setChipTarget,
        prevKeyRef,
        showTaskSearchPopup,
        setTaskSearchPopup,
        editChip,
        setEditChip,
        showEditChip,
        setShowEditChip,
        smartChipId,
        setSmartChipId,
        img,
        setImg,
        openImage,
        setOpenImage , 
        overallSearchRef , 
        modeRef , 
        selectedIndex, 
        setSelectedIndex , 
        containerRef , 
        tempChipID , setTempChipId , 
        editIframeId , setEditFrameId , 
        editIframe , setEditFrame,
        visibleDialog, setVisibleDialog
    } = useContext(editorContext);
  
    return {
        visible,
        setVisible,
        url,
        setUrl,
        selectedLink,
        setSelectedLink,
        taskInfo,
        setTaskInfo,
        cancelTokenRef,
        showInputPopup,
        setInputPopup,
        smartChipTarget,
        setChipTarget,
        prevKeyRef,
        showTaskSearchPopup,
        setTaskSearchPopup,
        editChip,
        setEditChip,
        showEditChip,
        setShowEditChip,
        smartChipId,
        setSmartChipId,
        img,
        setImg,
        openImage,
        setOpenImage , 
        overallSearchRef , 
        modeRef , 
        selectedIndex, 
        setSelectedIndex , 
        containerRef , 
        tempChipID , setTempChipId , 
        editIframeId , setEditFrameId , 
        editIframe , setEditFrame,
        visibleDialog, setVisibleDialog
    };
}

